import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { types } from '../types/types';
import { startLoading, finishLoading, filterClosed, filterOpened } from './ui';
import { makePOST, makeLogin } from '../helpers/makeRequest';
import { eraseCookie, getCookie, setCookie } from '../helpers/cookies';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const tokenSubject = new BehaviorSubject((getCookie('token')));
export const token = tokenSubject.asObservable()

export const startLoginEmailPassword = (email, password) => {
    return async (dispatch) => {
        dispatch(startLoading());
        return makePOST("auth/login", { email, password })
            .then((response) => {
                const data = response.data
                dispatch(login(data.token, email));
                setCookie('email', email, 1)
                setCookie('token', data.token, 1)
                setCookie('user', JSON.stringify(data.usuario))
                tokenSubject.next(data.token)
                toast.success('Inicio de sección correcto', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                dispatch(finishLoading());
                dispatch(filterOpened())
                dispatch(filterClosed())
                //redireccion a pagina de usuario
            })
            .catch(e => {
                console.log(e)
                dispatch(finishLoading());
                Swal.fire('Error', 'Por favor ingrese una contraseña o usuario válidos', 'error');
            })
    }
}

export const startLoginGoogle = (credential) => {
    return (dispatch) => {
        dispatch(startLoading());
        return makePOST("auth/loginGoogle", { credential })
            .then((response) => {
                const data = response.data
                dispatch(login(data.token, data.email));
                setCookie('email', data.email, 1)
                setCookie('token', data.token, 1)
                setCookie('user', JSON.stringify(data.usuario))
                tokenSubject.next(data.token)
                dispatch(finishLoading());
                toast.success('Inicio de sección correcto', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                dispatch(filterOpened())
                dispatch(filterClosed())
            })
            .catch(e => {
                dispatch(finishLoading());
                Swal.fire('Error', 'Ocurrió un error. Por favor, intente de nuevo más tarde.', 'error');
            })
    }
}

export const login = (token, email) => ({
    type: types.login,
    payload: { token, email }
});

export const startLogout = () => {
    return async (dispatch) => {
        eraseCookie('email')
        eraseCookie('token')
        eraseCookie('user')
        tokenSubject.next(null)
        dispatch(logout());
        toast.success('Sesión cerrada', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
}


export const isLoggedIn = () => {
    return tokenSubject.value != null
}

export const logout = () => ({
    type: types.logout
})


