import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { startLogout } from '../../actions/auth';
import { useDispatch, ReactReduxContext } from 'react-redux';
import { makeDELETE, makePOST } from '../../helpers/makeRequest';
import { FaComment, FaInstagram, FaRegCheckCircle, FaRegClock, FaStar, FaTimes, FaWhatsapp } from "react-icons/fa";
import useEmblaCarousel from 'embla-carousel-react'
import { FiFacebook, FiTwitter } from "react-icons/fi";
import Carousel from 'react-multi-carousel';
import { setCurrentBusiness, setOpenLoginForm, setSearchPage } from '../../actions/ui';
import GoogleMapReact from 'google-map-react';
import { useWindowDimensions } from '../../helpers/hooks';
import { Collapse } from 'react-collapse';
import Slider from '@mui/material/Slider';
import Select from "react-select";
import { TbWorld } from 'react-icons/tb';
import fondoblog from '../../imgs/fondo.avif'
import { Dialog, IconButton, DialogTitle, Slide, Pagination } from '@mui/material';
import encontraste from '../../imgs/tablet.webp'
import prueba1 from '../../imgs/prueba1.webp'
import prueba2 from '../../imgs/prueba2.webp'
import prueba3 from '../../imgs/prueba3.webp'
import prueba4 from '../../imgs/prueba4.avif'
import "../../styles/result-styles.css";
import { TfiFaceSad } from "react-icons/tfi";
import Heart from '@react-sandbox/heart'
import { getCookie } from '../../helpers/cookies';
import { Bling as GPT } from "react-gpt";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" duration={150} ref={ref} {...props} />;
});

export const ResultadoScreen = (props) => {
    const params = useParams()
    const { actualPage } = params
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    var store = useContext(ReactReduxContext).store.getState();
    const [options, setOptions] = useState([])

    const history = useHistory()
    const [reloading, setReloading] = useState(false)
    const [page, setPage] = useState(actualPage == ':actualPage' ? 'inicio' : actualPage)
    const handleChangeOfRoute = (route) => {
        setPage(route)
        const href = window.location.href.includes("?") ? window.location.href.substring(window.location.href.indexOf("?")) : ""
        history.push('/' + route + href)
    }

    const [locations, setLocations] = useState([
        {
            ubicacion: "Islas Maldivas, Italia",
            img: prueba1,
            score: 3.5,
            favorito: true,
        },
        {
            ubicacion: "Langhorne Manor, Pensilvania, United States",
            img: prueba2,
            score: 5,
            favorito: false,
        },
        {
            ubicacion: "Hopkins, Minnesota, United States",
            img: prueba3,
            score: 4.2,
            favorito: false,
        },
        {
            ubicacion: "Gainesville, Florida, United States",
            img: prueba4,
            score: 2.3,
            favorito: true,
        },
        {
            ubicacion: "Islas Maldivas, Italia",
            img: prueba1,
            score: 3.5,
            favorito: false,
        },
        {
            ubicacion: "Langhorne Manor, Pensilvania, United States",
            img: prueba2,
            score: 5,
            favorito: true,
        },
        {
            ubicacion: "Hopkins, Minnesota, United States",
            img: prueba3,
            score: 4.2,
            favorito: false,
        },
        {
            ubicacion: "Gainesville, Florida, United States",
            img: prueba4,
            score: 2.3,
            favorito: true,
        },
    ])

    const [emblaRef, emblaApi] = useEmblaCarousel()
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    const locationRoute = useLocation();
    useEffect(() => {
        const mapped = store.ui.info?.resultados?.map(v => {
            return {
                ...v,
                opened: false
            }
        }) || []

        setListado([...mapped])
    }, [store])

    const [pageNumber, setPageNumber] = useState(1)

    useEffect(() => {
        let splits = String(locationRoute.pathname).split("/").filter(v => v.length != 0)
        setPageNumber(parseInt(splits[splits.length - 1]))
    }, [locationRoute]);

    const handlePageChange = (page) => {
        if (pageNumber != page) {
            setPageNumber(page)
            let splits = String(locationRoute.pathname).split("/").filter(v => v.length != 0)
            splits.pop()
            handleChangeOfRoute(splits.join("/") + "/" + page)
            dispatch(setSearchPage(page))
        }
    }

    const defaultProps = {
        center: {
            lat: 19.42053,
            lng: -99.09412
        },
        zoom: 10
    };

    const renderMarkers = (map, maps) => {
        var bounds = new maps.LatLngBounds()

        listado.map(v => {
            const pos = new maps.LatLng(v.coordenadas[1], v.coordenadas[0])
            bounds.extend(pos)

            let marker = new maps.Marker({
                position: pos,
                map,
                title: v.nombre
            });
        })

        map.fitBounds(bounds)
    }

    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 3
        },
        mobil: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    const { height, width } = useWindowDimensions()
    const [mapView, setMapView] = useState(false)

    const changeValue = (idx, key, value) => {
        let tmp = [...listado]
        tmp[idx][key] = value

        setListado([...tmp])
    }

    const handleClose = (event, reason) => {
        setFlag(false)
        setFlag(false)
        return;

        setFlag(false)
        return;

    }

    const [listado, setListado] = useState([])
    const [flag, setFlag] = useState(false)
    const [value2, setValue2] = useState([20, 37])
    const [value1, setValue1] = useState(20)
    const minDistance = 10;

    const handleChange2 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setValue2([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setValue2([clamped - minDistance, clamped]);
            }
        } else {
            setValue2(newValue);
        }
    }

    const handleChange = (event, newValue, activeThumb) => {
        setValue1(newValue)
    }

    const validCoords = (coords) => {
        return Array.isArray(coords) ? ((!isNaN(coords[0]) && coords[0] != null) && (!isNaN(coords[1]) && coords[1] != null)) : false
    }

    const [colonia, setColonia] = useState(undefined)
    const [colonias, setColonias] = useState([
        { label: "Colonia 1", value: "Colonia 1" },
        { label: "Colonia 2", value: "Colonia 2" },
        { label: "Colonia 3", value: "Colonia 3" },
        { label: "Colonia 3", value: "Colonia 4" },
    ])

    const filtros = [
        <div className="form-check text-left">
            <p className='font-weight-bold m-0'>Calificación</p>
            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
            <label className="form-check-label" htmlFor="flexCheckChecked">
                <i data-star={4}></i> o más
            </label>
        </div>,
        <div>
            <p className='font-weight-bold m-0'>Precio</p>
            <p className='m-0'>${value2[0] + " - $" + value2[1]}</p>
            <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={value2}
                onChange={handleChange2}
                valueLabelDisplay="auto"
                disableSwap
            />
        </div>,
        <div>
            <p className='font-weight-bold m-0'>Distancia</p>
            <p className='m-0'>{value1}km</p>
            <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={value1}
                onChange={handleChange}
                valueLabelDisplay="auto"
                disableSwap
            />
        </div>,
        <div>
            <Select
                options={colonias}
                value={colonia}
                autoFocus={false}
                isSearchable={true}
                onChange={(e) => {
                    setColonia(e)
                }}
                placeholder="Colonia"
            />
        </div>,
    ]

    return (
        <>
            <div className={'row m-3 br-10'} style={{ maxWidth: width > 1400 ? "80%" : "" }}>
                {(width > 768 && false) &&
                    <div className='col-12 mt-3 mb-3'>
                        <Collapse isOpened={store?.ui?.filtroOpened}>
                            <div className='row'>
                                {
                                    filtros.map((v) => (
                                        <div className='col-3'>
                                            {v}
                                        </div>
                                    ))
                                }
                            </div>
                        </Collapse>
                    </div>
                }
                {(width <= 768) &&
                    <>
                        {mapView && <div className='col-12 text-center mb-3'>
                            {
                                !store?.ui?.loading &&
                                <div style={{ height: "50vh" }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                                        defaultCenter={defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                    >
                                    </GoogleMapReact>
                                </div>
                            }
                        </div>}
                    </>
                }
                {width >= 768 &&
                    <div className={`col-12 col-md-${mapView ? "6" : "4"} mb-3 d-none d-md-block sidebar-result`}>
                        <div id='div-gpt-ad-1720727048535-0'>
                            <GPT
                                adUnitPath="/121173452/seccion_amarilla/busqueda/halfbanner"
                                slotSize={[300, 600]}
                            />
                        </div>
                        {/* <Zoom duration={300}> */}
                        <div className="card text-white mb-4 border-0">
                            <img className="card-img br-15"
                                src={encontraste}
                                alt="Card image" />
                            <div className="card-img-overlay text-right d-flex align-items-end flex-column img-radius-result">
                                <p className="card-title m-0 card-font-home">¿Quieres hacer crecer tu negocio?</p>
                                <p className="card-text">Crea la ficha de tu empresa</p>
                                <a target='_blank' href="https://www.aceleradordigitaldenegocios.com.mx/"
                                    className="btn rounded-pill btn-yellow-black">
                                    Ve ahora
                                </a>
                            </div>
                        </div>
                        {/* </Zoom> */}
                        <div className='container mt-50'>
                            <div className='position-relative'>
                                <FaComment className='section-blog-result' color='#fd0' />
                                {/* <Slide> */}
                                <h2 id='conoceNuestroBlog' className='title-blog-result'>
                                    Conoce nuestro Blog
                                </h2>
                                {/* </Slide> */}
                            </div>
                            {/* <Zoom duration={300}> */}
                            <Carousel arrows={true} autoPlay={false} responsive={responsive} rewind={true}
                                rewindWithAnimation={true} className='carousel'
                                afterChange={(previousSlide, { currentSlide, onMove }) => {
                                }}>
                                {locations.map((slide, idx) => (
                                    <div className="card text-white border-0">
                                        <img className="card-img br-15"
                                            src={fondoblog}
                                            alt="Card image" />
                                        <div className="card-img-overlay text-right d-flex align-items-end flex-column img-radius-result">
                                            <p className="card-title m-0 blog-font-result">Logo</p>
                                            <div className='mt-auto w-75 mb-3'>
                                                <p className='blog-font-sub-result'>
                                                    Actividades para los niños en Semana Santa
                                                </p>
                                                <a href="https://blogseccion.amarilio.net/" target='_blank'
                                                    className="btn rounded-pill btn-yellow-black">
                                                    Leer más
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                            {/* </Zoom> */}
                        </div>
                    </div>
                }
                {listado.length != 0 ?
                    <>
                        <div itemScope="" itemType="https://schema.org/LocalBusiness" className={`col-12 col-md-${mapView ? "6" : "8"} container-result`}>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='h5'>
                                        {store.ui.info?.total > 20 ?
                                            "Mostrando resultados del " + (((20 * pageNumber) - 20) + 1)
                                            + " al " + ((20 * pageNumber)) + " de " + store.ui.info?.total :
                                            "Mostrando " + store.ui.info?.total + " resultados"
                                        }
                                    </p>
                                </div>
                                {store.ui.info?.total > 20 &&
                                    <div className='col-12 d-flex justify-content-center mb-3'>
                                        <Pagination count={Math.ceil(store.ui.info?.total / 20)} page={pageNumber} onChange={(e, v) => {
                                            handlePageChange(v)
                                        }} />
                                    </div>
                                }
                                {listado.map((v, i) => (
                                    <div role='button' className='col-12 col-md-6 p-0' key={i} duration={300}>
                                        <div key={i} className='bg-white w-100'>
                                            <div className='row m-0 p-0'>
                                                <div className='col-5 m-0 p-2'
                                                >
                                                    <div className='card-img-top p-2 position-relative'>
                                                        {validCoords(v.coordenadas) &&
                                                            <FaRegCheckCircle className='verify-mini-result' />
                                                        }
                                                        {
                                                            <div className='heart-div-section-white'>
                                                                <Heart className={!v.favorito ? "heart-home-not" : "heart-svg"}
                                                                    width={24} inactiveColor='lightgrey' active={v.favorito} onClick={() => {
                                                                        if (getCookie("token")) {
                                                                            if (v.favorito) {
                                                                                makeDELETE("favourites/delete",
                                                                                    {
                                                                                        id_favourite: v?.favourite_info?.id_favourite
                                                                                    })
                                                                            } else {
                                                                                makePOST("favourites/add",
                                                                                    {
                                                                                        business_id: v.listadoid,
                                                                                        list_name: "general"
                                                                                    })
                                                                            }
                                                                            changeValue(i, "favorito", !v.favorito)
                                                                        } else {
                                                                            dispatch(setOpenLoginForm(true))
                                                                        }
                                                                    }} />
                                                            </div>
                                                        }
                                                        <img itemProp="image" className="w-100 p-1 aspect-image-result" key={i}
                                                            onClick={() => {
                                                                if (width > 768) {
                                                                    handleChangeOfRoute("informacion/" + v.nombre.toLowerCase().replace(/ /g, '-')
                                                                        + "/" + v.categoria.toLowerCase().replace(/ /g, '-') + "/" + v.listadoid)
                                                                } else {
                                                                    setFlag(true)
                                                                }
                                                                dispatch(setCurrentBusiness({ ...v }))
                                                            }}
                                                            src={locations[i % 4]?.img} alt="Cambiar por el nombre de la imagen" />
                                                    </div>
                                                </div>
                                                <div className='col-7 pl-0 pt-3 pr-3 pb-0'
                                                    onClick={() => {
                                                        if (width > 768) {
                                                            handleChangeOfRoute("informacion/" + v.nombre.toLowerCase().replace(/ /g, '-')
                                                                + "/" + v.categoria.toLowerCase().replace(/ /g, '-') + "/" + v.listadoid)
                                                        } else {
                                                            setFlag(true)
                                                        }
                                                        dispatch(setCurrentBusiness({ ...v }))
                                                    }}>
                                                    <div className='text-left'>
                                                        <h2 itemProp="name" className="card-title p-0 m-0 h5 wrapText">{v.nombre}</h2>
                                                        <p itemScope itemType="https://schema.org/PostalAddress" className="card-text m-0 fs-13">
                                                            <span itemProp="addressRegion">{v.ciudad + ", " + v.estado}</span>
                                                        </p>
                                                        {v.distancia &&
                                                            <p className="fs-12 m-0">
                                                                A {v.distancia} km de ti
                                                            </p>
                                                        }
                                                        <p className='p-0 m-0 font-star-result'>
                                                            <FaStar itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating" color="yellow" /> {v.score}
                                                            <span itemProp="reviewCount" className='font-review-result'> (23 reviews)</span>
                                                        </p>
                                                        {JSON.stringify(v?.horarios) != "{}" &&
                                                            (v?.horarioInfo?.isOpened ?
                                                                <>
                                                                    <FaRegClock />&nbsp; <span className='green-home'>Abierto
                                                                    </span>
                                                                </> :
                                                                <>
                                                                    <FaRegClock />&nbsp; <span className='text-danger'>Cerrado
                                                                    </span>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {store.ui.info?.total > 20 &&
                                    <div className='col-12 d-flex justify-content-center mt-3'>
                                        <Pagination count={Math.ceil(store.ui.info?.total / 20)} page={pageNumber} onChange={(e, v) => {
                                            handlePageChange(v)
                                        }} />
                                    </div>
                                }
                            </div>
                        </div>
                        {/*<>
                            <div className='col-12 text-center'>
                                <p className='h3'>Listados recientes</p>
                            </div>
                            <div className='col-12 mb-4'>
                                <div className="embla__viewport" ref={emblaRef}>
                                    <div className="embla__container">
                                        {listado.map((v, i) => (
                                            <div className="embla__slide" key={i}>
                                                <div key={i} className='ml-1 mr-1 h-100'>
                                                    <div className="card b-list-result-mobile">
                                                        <div className="card-body p-0 pr-3 pl-3 pt-3">
                                                            <div className='row text-muted'>
                                                                <div className='col-8 pr-0' onClick={() => {
                                                                    setFlag(true)
                                                                    dispatch(setCurrentBusiness({ ...v }))
                                                                }}>
                                                                    <h2 className="card-title p-0 m-0 h5">{v.nombre}</h2>
                                                                    <p className="card-text">
                                                                        {v.ciudad + ", " + v.estado}
                                                                    </p>
                                                                </div>
                                                                <div className='col-4 text-right m-0 p-0 pr-2'>
                                                                    <a href="tel:5555555555" className="btn rounded-pill btn-block btn-yellow-list">
                                                                        LLAMAR
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className='row mt-2'>
                                                                <div className='col-8 pr-0' onClick={() => {
                                                                    setFlag(true)
                                                                    dispatch(setCurrentBusiness({ ...v }))
                                                                }}>
                                                                    <p className='p-0 m-0'>
                                                                        <i data-star={v.score}></i> {v.score}
                                                                    </p>
                                                                </div>
                                                                <div className='col-4 text-center pl-0 fs-22'>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </>*/}
                        <Dialog
                            open={flag}
                            maxWidth={width > 768 && "md"}
                            TransitionComponent={Transition}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    borderRadius: "15px"
                                },
                                sx: {
                                    mt: "60px",
                                    verticalAlign: "top"
                                }
                            }}
                            sx={{
                                "& .MuiDialog-container": {
                                    "& .MuiPaper-root": {
                                        width: "100%",
                                        //maxWidth: "500px",
                                    },
                                },
                            }}
                            scroll="body"
                        >
                            <DialogTitle id="id" className='p-0 m-0'>
                                <IconButton onClick={() => {
                                    setFlag(false)
                                }}>
                                    <FaTimes />
                                </IconButton>
                            </DialogTitle>
                            <div>
                                <div className='row m-0 p-0'>
                                    <div className='col-12 mb-2 pt-3'>
                                        {/* <Zoom duration={150} triggerOnce> */}
                                        <Carousel arrows={true} responsive={responsive1} rewind={true}
                                            rewindWithAnimation={true} style={{ zIndex: 200 }}>
                                            {locations.map((v, i) => (
                                                <div className='m-1' key={i}>
                                                    <img key={i} className="w-100"
                                                        style={{
                                                            borderRadius: "15px", aspectRatio: "1 / 1", objectFit: "cover", maxHeight: "500px"
                                                        }}
                                                        src={v.img} alt="Card image cap" />
                                                </div>
                                            ))}
                                        </Carousel>
                                        {/* </Zoom> */}
                                    </div>
                                    <div className='col-12 mb-4 pt-3'>
                                        {/* <Zoom duration={150} triggerOnce> */}
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className='h3'>
                                                    {store?.ui?.currentBusiness?.nombre}
                                                </p>
                                                <p className='h6 text-muted'>
                                                    A {store?.ui?.currentBusiness?.distancia}km de ti
                                                </p>
                                            </div>
                                            <div className='col-12'>
                                                <p className='p-0 m-0 text-muted'>
                                                    {store?.ui?.currentBusiness?.score} <i data-star={store?.ui?.currentBusiness?.score}></i> <u>20 evaluaciones</u>
                                                </p>
                                                <div className='row' style={{ fontSize: "30px" }}>
                                                    <div className='col-7'>
                                                        <FaInstagram className='mr-4' />
                                                        <FaWhatsapp className='mr-4' />
                                                        <FiFacebook className='mr-4' />
                                                        <FiTwitter className='mr-4' />
                                                    </div>
                                                    <div className='col-5 text-right'>
                                                        <a href="https://www.seccionamarilla.com.mx" className="btn rounded-pill"
                                                            style={{
                                                                backgroundColor: "black", color: "white", fontWeight: "bold"
                                                            }}>
                                                            <TbWorld style={{ fontSize: "30px" }} /> Ir al sitio
                                                        </a>
                                                    </div>
                                                    <div className='col-12 mt-3'>
                                                        <p className='h5 font-weight-bold'>
                                                            Sobre nosotros
                                                        </p>
                                                        <p className='h5 text-muted'>
                                                            Lorem ipsum dolor sit amet, consectetuer
                                                            adipiscing elit, sed diam nonummy nibh
                                                            euismod tincidunt ut laoreet...
                                                        </p>
                                                        <button className="btn rounded-pill"
                                                            style={{
                                                                backgroundColor: "#ffdd00", color: "white", fontWeight: "bold"
                                                            }}
                                                            onClick={() => {
                                                                handleChangeOfRoute("informacion/" + store?.ui?.currentBusiness?.nombre.toLowerCase().replace(/ /g, '-')
                                                                    + "/" + store?.ui?.currentBusiness?.categoria.toLowerCase().replace(/ /g, '-') + "/" + store?.ui?.currentBusiness?.listadoid)
                                                                setFlag(false)
                                                            }}>
                                                            VER MÁS
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </Zoom> */}
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </> :
                    <div className='col-12 col-md-8 text-center'>
                        <TfiFaceSad style={{ fontSize: "45px" }} />
                        <p className='h5'>
                            Ups! No encontramos resultados en tu búsqueda
                        </p>
                    </div>
                }
            </div >
        </>
    )
}
