import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { startLogout } from '../../actions/auth';
import { useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import { FaEdit, FaEye, FaEyeSlash, FaHeart, FaKey, FaRegTrashAlt, FaTimes, FaUser } from "react-icons/fa";
import { useWindowDimensions } from '../../helpers/hooks';
import { getCookie, setCookie } from '../../helpers/cookies';
import TextField from '@mui/material/TextField'
import { validateEmail } from '../../helpers/validators';
import { IoIosLogOut } from "react-icons/io";
import { Slide, Dialog, DialogTitle, IconButton } from '@mui/material';
import { makeDELETE, makeGET, makePATCH, makePOST } from '../../helpers/makeRequest';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDsk = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const UsuarioScreen = (props) => {
    const params = useParams()
    const { actualPage } = params
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    const [imgSrc, setImgSrc] = useState("https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Google_Maps_pin.svg/20px-Google_Maps_pin.svg.png")

    const [options, setOptions] = useState([])
    const [openedHours, setOpenedHours] = useState(false)

    const history = useHistory()
    const [formChanged, setFormChanged] = useState(false)
    const [page, setPage] = useState(actualPage == ':actualPage' ? 'inicio' : actualPage)
    const handleChangeOfRoute = (route) => {
        setPage(route)
        const href = window.location.href.includes("?") ? window.location.href.substring(window.location.href.indexOf("?")) : ""
        history.push('/' + route + href)
    }

    const { height, width } = useWindowDimensions()
    const [form, setForm] = useState({
        first_name: JSON.parse(getCookie("user"))?.first_name,
        last_name: JSON.parse(getCookie("user"))?.last_name,
        foto_url: JSON.parse(getCookie("user"))?.foto_url,
        zip_code: JSON.parse(getCookie("user"))?.zip_code,
        email: getCookie("email"),
    })

    const undReturn = (val) => {
        if (val == undefined) {
            return ""
        }
        return val
    }

    const [deleteFlag, setDeleteFlag] = useState(false)

    useEffect(async () => {
        if (!getCookie("user")) handleChangeOfRoute("")
    }, [])

    useEffect(async () => {
        const resp = await makeGET("user/info")
        if (resp.status == 200) {
            setCookie("user", JSON.stringify({ ...resp.data }))
            setForm({ ...resp.data })
        }
    }, [])

    const handleClose = () => {
        setDeleteFlag(false);
    };

    const modifyAccount = async () => {
        const resp = await makePOST("user/modify", { ...form })
        if (resp.status == 200) {
            toast.success('Éxito! Su cuenta fue modificada correctamente.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            setCookie("user", JSON.stringify({ ...form }))
        } else {
            toast.warning('Ocurrió un error desconocido. Intente de nuevo más tarde.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const deleteAccount = async () => {
        const resp = await makeDELETE("user/delete", { password })
        if (resp.status == 200) {
            handleChangeOfRoute("")
            handleLogout()
        } else {
            toast.warning('Ocurrió un error desconocido. Intente de nuevo más tarde.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    }

    const [passShowedLogin, setPassShowedLogin] = useState(false)
    const [passShowedLoginConf, setPassShowedLoginConf] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordConf, setPasswordConf] = useState('')

    return (
        <div className='row p-3' style={{
            borderRadius: "10px", width: width > 1400 ? "80%" : "",
            height: width > 768 ? ((height - 60) + "px") : "auto"
        }}>
            <div className='col-12 col-md-4 text-center'>
                <div className={'row p-2'} style={{ borderRadius: "15px", }}>
                    <div className='col-12 mb-3'>
                        {JSON.parse(getCookie("user"))?.foto_url ?
                            <>
                                <img src={JSON.parse(getCookie("user"))?.foto_url}
                                    onClick={() => { window.open(JSON.parse(getCookie("user"))?.foto_url) }} width={"96px"}
                                    className='rounded-circle' role='button' /></> :
                            <FaUser style={{ fontSize: "96px" }} />
                        }
                        <br />
                        {/* <a href='#'><FaEdit />&nbsp;Subir</a> */}
                    </div>
                    <div className='col-12 mb-3'>
                        <div className='col-12 mb-3 text-center'>
                            <button onClick={() => { handleChangeOfRoute("favoritos") }} className='btn btn-primary btn-block'>
                                <FaHeart />&nbsp;Favoritos
                            </button>
                        </div>
                    </div>
                    <div className='col-12 mb-3'>
                        <div className='col-12 mb-3 text-center'>
                            <button onClick={() => { setDeleteFlag(!deleteFlag) }} className='btn btn-dark btn-block'>
                                <FaRegTrashAlt />&nbsp;Eliminar cuenta
                            </button>
                        </div>
                    </div>
                    <div className='col-12 mb-3'>
                        <div className='col-12 mb-3 text-center'>
                            <button onClick={() => {
                                handleLogout()
                                handleChangeOfRoute("")
                            }} className='btn btn-danger btn-block'>
                                <IoIosLogOut />&nbsp;Cerrar sesión
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-8 text-center border-left'>
                <div className={'row p-2'} style={{ borderRadius: "15px", }}>
                    <div className='col-12 mb-3'>
                        <p className='h3'>Tus datos</p>
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                        <TextField
                            type="email"
                            fullWidth
                            onChange={(e) => {
                                setForm({ ...form, email: e.target.value })
                                setFormChanged(true)
                            }}
                            value={undReturn(form.email)}
                            autoComplete="off"
                            placeholder={""}
                            disabled
                            required
                            error={(!validateEmail(form.email) && form.email != undefined)}
                            helperText={(!validateEmail(form.email) && form.email != undefined) && "Formato de email no válido"}
                            variant="outlined"
                            label="Email"
                            style={{ marginBottom: (!validateEmail(form.email) && form.email != undefined) && '5px' }}
                        />
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                        <TextField
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setForm({ ...form, first_name: e.target.value })
                                setFormChanged(true)
                            }}
                            value={undReturn(form.first_name)}
                            label="Nombre"
                            placeholder=""
                            //disabled={flag}
                            required
                            variant="outlined"
                            error={(form.first_name?.length == 0 && form.first_name != undefined)}
                            helperText={(form.first_name?.length == 0 && form.first_name != undefined) && "Este campo es obligatorio"}
                            style={{ marginBottom: (form.first_name?.length == 0 && form.first_name != undefined) && '5px' }}
                        />
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                        <TextField
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setForm({ ...form, last_name: e.target.value })
                                setFormChanged(true)
                            }}
                            value={undReturn(form.last_name)}
                            label="Apellidos"
                            placeholder=""
                            //disabled={flag}
                            required
                            variant="outlined"
                            error={(form.last_name?.length == 0 && form.last_name != undefined)}
                            helperText={(form.last_name?.length == 0 && form.last_name != undefined) && "Este campo es obligatorio"}
                            style={{ marginBottom: (form.last_name?.length == 0 && form.last_name != undefined) && '5px' }}
                        />
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                        <TextField
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, "").substring(0, 5);
                                setForm({ ...form, zip_code: value })
                                setFormChanged(true)
                            }}
                            value={undReturn(form.zip_code).replace(/\D/g, "")}
                            label="Código postal"
                            placeholder=""
                            //disabled={flag}
                            required
                            variant="outlined"
                            error={(form.zip_code?.length == 0 && form.zip_code != undefined)}
                            helperText={(form.zip_code?.length == 0 && form.zip_code != undefined) && "Este campo es obligatorio"}
                            style={{ marginBottom: (form.zip_code?.length == 0 && form.zip_code != undefined) && '5px' }}
                        />
                    </div>
                    <div className='col-12 mb-3 text-center'>
                        <button className='btn btn-primary' onClick={() => { modifyAccount() }}>
                            Modificar
                        </button>
                    </div>
                </div>
            </div>
            <Dialog
                open={deleteFlag}
                maxWidth={width > 768 && "md"}
                TransitionComponent={width <= 768 ? Transition : TransitionDsk}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        borderRadius: "15px"
                    },
                    sx: {
                        mt: "60px",
                        verticalAlign: "top"
                    }
                }}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            //maxWidth: "500px",
                        },
                    },
                }}
                scroll="body"
            >
                <DialogTitle id="id" className='d-flex p-0 m-0'>
                    <div className=''>
                        <IconButton onClick={() => {
                            setDeleteFlag(false)
                        }}>
                            <FaTimes />
                        </IconButton>
                    </div>
                </DialogTitle>
                <div className='row m-0 p-0'>
                    <div className='col-12 p-3 pt-0 text-center'>
                        <p className='h3'>¿Estás seguro de querer eliminar tu cuenta?</p>
                        <p className='h4'>Esta acción es irreversible. <br />
                            {form?.login_method == "standard" ? "Por favor, confirma tu contraseña para continuar" : ""}</p>
                    </div>
                    {form?.login_method == "standard" &&
                        <>
                            <div className='col-12 col-md-6'>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"
                                            style={{ color: 'white', background: '#ffdd00', borderColor: '#ffdd00' }}>
                                            <FaKey />
                                        </span>
                                    </div>
                                    <input
                                        type={passShowedLogin ? "text" : "password"}
                                        placeholder="Confirmar contraseña"
                                        name="password"
                                        autoCapitalize="off"
                                        autoCorrect="off"
                                        className="form-control form-control-lg"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)} />
                                    <div className="input-group-append">
                                        <button className="btn" type="button"
                                            style={{ color: 'white', background: '#ffdd00', borderColor: '#ffdd00' }}
                                            onClick={() => setPassShowedLogin(!passShowedLogin)}>
                                            {passShowedLogin ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"
                                            style={{ color: 'white', background: '#ffdd00', borderColor: '#ffdd00' }}>
                                            <FaKey />
                                        </span>
                                    </div>
                                    <input
                                        type={passShowedLoginConf ? "text" : "password"}
                                        placeholder="Confirmar contraseña"
                                        name="password"
                                        autoCapitalize="off"
                                        autoCorrect="off"
                                        className="form-control form-control-lg"
                                        value={passwordConf}
                                        onChange={e => setPasswordConf(e.target.value)} />
                                    <div className="input-group-append">
                                        <button className="btn" type="button"
                                            style={{ color: 'white', background: '#ffdd00', borderColor: '#ffdd00' }}
                                            onClick={() => setPassShowedLoginConf(!passShowedLoginConf)}>
                                            {passShowedLoginConf ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='col-12 text-center mb-3'>
                        <button className="btn" type="button" onClick={() => { deleteAccount() }}
                            style={{ color: 'white', background: '#ffdd00', borderColor: '#ffdd00' }}>
                            Confirmar
                        </button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
