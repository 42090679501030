import axios from "axios";
import { startLogout, tokenSubject } from "../actions/auth.js";
import { conf } from "../config/config.js";
import { eraseCookie, getCookie } from "./cookies.js";
const baseURL = conf.apiURL;

export const makeGETCUSTOM = (endpoint = '') => {
    let headers = {
        'Content-Type': 'application/json',
    }

    return sec(axios.get(endpoint, { headers }))
}

export const makeGET = (endpoint = '', query = '') => {
    let headers = {
        'Content-Type': 'application/json',
    }

    if (getCookie("token")) {
        headers.Authorization = getCookie("token")
    }

    return sec(axios.get(baseURL + endpoint + query, { headers }))
}

export const makePOST = (endpoint = '', body = '', isFormData = false) => {
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json',
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (getCookie("token")) {
        headers.Authorization = getCookie("token")
    }

    return sec(axios.post(baseURL + endpoint, formatBody, { headers }))
}

export const makePATCH = (endpoint = '', body = '', isFormData = false) => {
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json',
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (getCookie("token")) {
        headers.Authorization = getCookie("token")
    }
    return sec(axios.patch(baseURL + endpoint, formatBody, { headers }))
}

export const makeDELETE = (endpoint = '', body = '') => {
    var data = JSON.stringify(body)
    var config = {
        method: 'delete',
        url: baseURL + endpoint,
        headers: {
            'Authorization': (getCookie("token")),
            'Content-Type': 'application/json'
        },
        data: data
    };

    return sec(axios(config))
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
export const detectMob = () => {
    return ((window.innerWidth <= 768));
}
export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const toMillions = (n) => {
    const letter = n > 1000000000 ? "B" : "M"
    return n > 1000000 ? numberWithCommas(n).split(",")[0] + "."
        + numberWithCommas(n).split(",")[1].charAt(0) + letter : numberWithCommas(n)
}

export const returnNumber = (n) => {
    if (n) {
        return n >= 1000000 ? toMillions(n) : numberWithCommas(n)
    }
    return ""
}

export const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
export const loadQueryString = () => {
    var parameters = {};
    var searchString = window.location.href.includes("?") ? window.location.href.substring(window.location.href.indexOf("?") + 1) : ""
    var pairs = searchString.split("&");
    var parts;
    for (let i = 0; i < pairs.length; i++) {
        parts = pairs[i].split("=");
        var name = parts[0];
        var data = decodeURI(parts[1]);
        parameters[name] = data;
    }
    return parameters;
}

const sec = async (request) => {
    try {
        let response = await request
        return response
    } catch (e) {
        const err = e.response || JSON.parse(JSON.stringify(e))
        if (err?.status == 422) {
            eraseCookie('email')
            eraseCookie('token')
            tokenSubject.next(null)
        }
        throw e
    }
}
