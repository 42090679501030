export const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',

    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',

    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    filtroOpened: '[UI] Start filtering',
    filtroClosed: '[UI] Finish finish',

    searchOpened: '[UI] Start search',
    searchClosed: '[UI] Finish search',
}