import React, { useEffect, useState, lazy } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect, HashRouter
} from 'react-router-dom';

import { useDispatch } from 'react-redux';

//import { firebase } from '../firebase/firebase-config'
import { AuthRouter } from './AuthRouter';
import { PrivateRoute } from './PrivateRoute';
import { token, login } from '../actions/auth';
import { PublicRoute } from './PublicRoute';
import { PrincipalScreen } from '../components/main/PrincipalScreen';
import { ResultadoScreen } from '../components/main/ResultadoScreen';
import { setParams } from '../actions/ui';
import { getCookie } from '../helpers/cookies';
const InformacionScreen = lazy(() =>
    import("../components/main/InformacionScreen")
)

export const AppRouter = (props) => {
    const dispatch = useDispatch();
    const [checking, setChecking] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        token.subscribe(x => {
            if (x) {
                setIsLoggedIn(true);
                dispatch(login(x, getCookie("email")))
            } else {
                setIsLoggedIn(false);
            }
            setChecking(false);
        });
        setIsLoggedIn(true);
        setChecking(false);
        dispatch(setParams(props))
    }, [dispatch, setChecking, setIsLoggedIn])

    /*useEffect(() => {
        console.log(JSON.parse(localStorage.getItem("userProps")))
        console.log(userData)
    }, [isLoggedIn])*/

    /*if (checking) {
        return (
            <h1>Espere...</h1>
        )
    }*/


    return (
        <Router>
            <div>
                <Switch>
                    {/* <PublicRoute
                        path="/auth"
                        component={AuthRouter}
                        isAuthenticated={isLoggedIn}
                        initPage={"/inicio"}
                    />

                    <PrivateRoute
                        exact
                        isAuthenticated={isLoggedIn}
                        path="/:actualPage"
                        component={PrincipalScreen}
                    />

                    <Redirect to="/auth/login" /> 
                    <PublicRoute
                        path="/auth"
                        component={AuthRouter}
                        isAuthenticated={true}
                        //initPage={JSON.parse(localStorage.getItem("userProps"))?.rol == "admin" ? "/inicio" : "/franquicia"}
                        initPage="/principal"
                    />*/}

                    {/* <PublicRoute
                        path="/auth"
                        component={AuthRouter}
                        isAuthenticated={true}
                        //initPage={JSON.parse(localStorage.getItem("userProps"))?.rol == "admin" ? "/inicio" : "/franquicia"}
                        initPage="/inicio"
                    /> */}

                    <PrivateRoute
                        isAuthenticated={true}
                        path="/:actualPage"
                        component={PrincipalScreen}
                    />

                    {/* <PrivateRoute
                        isAuthenticated={true}
                        path="/informacion/:negocio/:categoria/:id"
                        component={InformacionScreen}
                    /> */}

                    <PrivateRoute
                        isAuthenticated={true}
                        path="/"
                        component={PrincipalScreen}
                    />

                    {/* <PrivateRoute
                        isAuthenticated={true}
                        path="/resultados"
                        component={ResultadoScreen}
                    /> */}

                    {/* <Redirect to="/principal" /> */}

                </Switch>
            </div>
        </Router>
    )
}