import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { isLoggedIn, startLogout } from '../../actions/auth';
import { useSelector, useDispatch, ReactReduxContext } from 'react-redux';
import { ResultadoScreen } from './ResultadoScreen';
import { FavoriteScreen } from './FavoriteScreen';
import logo from '../../imgs/seccionamarilla_transp.png'
import { InicioScreen } from './InicioScreen';
import Hamburger from 'hamburger-react';
import { makeGET, makeGETCUSTOM, sampleData } from '../../helpers/makeRequest';
import Autocomplete from 'react-autocomplete';
import { IoLocationSharp, IoSchoolSharp } from "react-icons/io5"
import { MdCardTravel, MdHotel, MdOutlinePets } from "react-icons/md";
import { FaBriefcaseMedical, FaBuilding, FaRegUser, FaSearch, FaTimes, FaTools, FaUser } from "react-icons/fa";
import { FaComputer, FaLocationPin } from "react-icons/fa6";
import useEmblaCarousel from 'embla-carousel-react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Autoplay from 'embla-carousel-autoplay'
import useOnScreen from '../../helpers/hooks';
import Spinner from 'react-bootstrap/Spinner';
import { InformacionScreen } from './InformacionScreen';
import { Collapse } from 'react-collapse';
import { IoIosArrowBack, IoIosArrowUp, IoIosLogOut, IoMdHeart, IoMdHeartEmpty, IoMdHome } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { CiHeart, CiUser } from "react-icons/ci";
import { GoHome, GoLaw, GoHomeFill } from "react-icons/go";
import { setTerm, setLocate, setBusinessInfo, startLoading, finishLoading, setCoords, setOpenLoginForm } from '../../actions/ui';
import { FiSearch } from "react-icons/fi";
import { LuSettings2 } from "react-icons/lu";
import Slider from '@mui/material/Slider';
import Select from "react-select";
import Fade from "react-reveal";
import { Dialog, IconButton, DialogTitle } from '@mui/material';
import Slide from '@mui/material/Slide';
import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Zoom } from 'react-awesome-reveal';
import { useWindowDimensions } from '../../helpers/hooks';
import { LoginScreen } from '../auth/LoginScreen';
import footerlinks from '../../imgs/bottom-footer.png'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getCookie } from '../../helpers/cookies';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { UsuarioScreen } from './UsuarioScreen';
import {
    setDefaults,
} from "react-geocode";
import { ImLab } from "react-icons/im";
import { VscLaw } from "react-icons/vsc";
import { GrUserWorker } from "react-icons/gr";
import { GiMetalBar } from "react-icons/gi";
/*const InicioScreen = lazy(() =>
    import("./InicioScreen.js")
)*/
import svgEscuelas from '../../imgs/icons/icon-escuelas.svg'
import svgHoteles from '../../imgs/icons/icon-hoteles.svg'
import svgHospitales from '../../imgs/icons/icon-medicos.svg'
import svgMecanicos from '../../imgs/icons/icon-mecanicos.svg'
import svgAbogados from '../../imgs/icons/icon-abogados.svg'
import svgConstruccion from '../../imgs/icons/icon-construccion.svg'
import svgFerreteria from '../../imgs/icons/icon-ferreteria.svg'
import svgFumigaciones from '../../imgs/icons/icon-fumigaciones.svg'
import svgLaboratorios from '../../imgs/icons/icon-laboratorios.svg'
import svgNotario from '../../imgs/icons/icon-notario.svg'
import svgFerreterias from '../../imgs/icons/icon-mecanicos.svg'
import svgBusqueda from '../../imgs/icons/icon-busqueda-barra.svg'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDsk = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export const PrincipalScreen = (props) => {
    const { filtroOpened } = useSelector(state => state.ui);

    const params = useParams()
    const { actualPage } = params
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    setDefaults({
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: "es",
        region: "mx",
    });

    const locationRoute = useLocation();
    useEffect(() => {
        if (locationRoute.pathname.startsWith("/informacion")) {
            setIsOpenCollapse(false)
        } else {
            setIsOpenCollapse(true)
        }

        ref?.current?.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }, [locationRoute]);
    const [isOpen, setOpen] = useState(false)

    const history = useHistory()
    const [reloading, setReloading] = useState(false)
    const [principalFlag, setPrincipalFlag] = useState(false)

    const [page, setPage] = useState(actualPage == ':actualPage' ? 'inicio' : actualPage)
    const handleChangeOfRoute = (route) => {
        setPage(route)
        const href = window.location.href.includes("?") ? window.location.href.substring(window.location.href.indexOf("?")) : ""
        history.push('/' + route + href)
    }

    var store = useContext(ReactReduxContext).store.getState();

    const [options, setOptions] = useState([])
    const [flag, setFlag] = useState(false)
    const [optionsMap, setOptionsMap] = useState([])
    const [optionSelected, setOptionSelected] = useState(store?.ui?.term || "")
    const [info, setInfo] = useState([])

    const handleInput = async (term) => {
        try {
            const resp = await makeGET("autocomplete?term=" + term)
            const opciones = resp.data.map(v => {
                const str = v.toLowerCase()
                return str.charAt(0).toUpperCase() + str.slice(1)
            })
            setOptions(opciones)
            setOptionsMap(opciones.map(v => {
                return {
                    label: v
                }
            }))
        } catch (e) {
            console.log(e)
        }
    }

    const handleInputLoc = async (term) => {
        if (term.length == 0) {
            setOptions2([])
            setOptionsMap2([])
            return
        }

        try {
            const resp = await makeGET("autocomplete?place=" + term)
            setOptions2(resp.data)
            setOptionsMap2(resp.data.map(v => {
                return {
                    label: v
                }
            }))
        } catch (e) {
            console.log(e)
        }
    }

    const handleSubmit = async (page) => {
        try {
            setLoading(true)
            dispatch(startLoading())

            let pos

            try {
                pos = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                })
            } catch (error) {
                pos = undefined
            }

            const physicalcity =
                false ?
                    (location?.address?.suburb || location?.address?.city || location?.address?.county)
                        .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : optionSelected2.split(", ")[0]

            const physicalstate =
                false ?
                    (location?.address?.suburb ? location?.address?.city : location?.address?.state)
                        .normalize("NFD").replace(/[\u0300-\u036f]/g, "") : optionSelected2.split(", ")[1]

            const term = optionSelected.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            let { data } = await makeGET(
                `search?term=${term}&state=${physicalstate}&city=${physicalcity}` +
                (pos ? `&lat=${pos.coords.latitude}&lng=${pos.coords.longitude}&page=${page}` : ``)
            )

            data.resultados = data?.resultados?.map(v => {
                return {
                    ...v,
                    horarioInfo: isOpened(v?.horarios)
                }
            })

            dispatch(setBusinessInfo(data))
            setInfo(data)
            setLoading(false)
            dispatch(finishLoading())
            const route = ("resultados/" + term +
                (physicalstate ? "/" + physicalstate : "") + (physicalcity ? "/" + physicalcity : "") + "/" + page).toLowerCase().replace(/ /g, '-')
            console.log("1")
            setFlag(false)
            setSearchNumber(searchNumber + 1)
            document.getElementById("inputMobile").blur()
            handleChangeOfRoute(route)
        } catch (e) {
            console.log(e)
        }
    }

    const isOpened = (horarios) => {
        const wrapHorario = (hora) => {
            hora = String(hora)
            let split = hora.split(":")
            split.pop()
            return split.join(":")
        }
        let horariosTmp = [], horariosRange = []
        let isOpened = false
        if (horarios) {
            if (horarios?.semana) {
                horariosTmp = [...horariosTmp, "Lunes a Viernes: " + wrapHorario(horarios?.semana?.open)
                    + " - " + wrapHorario(horarios?.semana?.close)]
                horariosRange = [...horariosRange, {
                    days: [1, 2, 3, 4, 5],
                    open: parseInt((horarios?.semana?.open)?.split(":")[0]),
                    close: parseInt((horarios?.semana?.close)?.split(":")[0]),
                }]
            }
            if (horarios?.sabado) {
                horariosTmp = [...horariosTmp, "Sabado: " + wrapHorario(horarios?.sabado?.open)
                    + " - " + wrapHorario(horarios?.sabado?.close)]
                horariosRange = [...horariosRange, {
                    days: [6],
                    open: parseInt((horarios?.sabado?.open)?.split(":")[0]),
                    close: parseInt((horarios?.sabado?.close)?.split(":")[0]),
                }]
            }
            if (horarios?.domingo) {
                horariosTmp = [...horariosTmp, "Domingo: " + wrapHorario(horarios?.domingo?.open)
                    + " - " + wrapHorario(horarios?.domingo?.close)]
                horariosRange = [...horariosRange, {
                    days: [0],
                    open: parseInt((horarios?.domingo?.open)?.split(":")[0]),
                    close: parseInt((horarios?.domingo?.close)?.split(":")[0]),
                }]
            }
        }

        const currentDate = new Date()
        const finded = horariosRange.find(v => v.days.includes(currentDate.getDay()))
        if (finded?.open <= currentDate.getHours() && finded?.close >= currentDate.getHours()) {
            isOpened = true
        }
        return {
            isOpened, nextHour: ((isOpened ? finded?.close : finded?.open) + "hrs")
        }
    }

    const [options2, setOptions2] = useState([])
    const [optionsMap2, setOptionsMap2] = useState([])
    const [optionSelected2, setOptionSelected2] = useState(store?.ui?.location || "")
    const [explicitCategory, setExplicitCategory] = useState("")

    const [location, setLocation] = useState(undefined)
    const [position, setPosition] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [isOpenCollapse, setIsOpenCollapse] = useState(true)

    const getLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async position => {
                dispatch(setCoords({ lat: position.coords.latitude, lng: position.coords.longitude }))

                if (actualPage == undefined) {
                    const { data: { ciudad, estado } } = await makeGET(`geocode?lat=${position.coords.latitude}&lng=${position.coords.longitude}`)
                    if (ciudad?.length > 0 && estado?.length > 0) {
                        setOptionSelected2(ciudad + ", " + estado)
                    }
                }

                /*fromLatLng(position.coords.latitude, position.coords.longitude)
                    .then(({ results }) => {
                        let ciudad, estado
                        for (let ac = 0; ac < results[0].address_components.length; ac++) {
                            let component = results[0].address_components[ac]
                            switch (component.types[0]) {
                                case 'locality':
                                    ciudad = component.long_name
                                    break
                                case 'administrative_area_level_1':
                                    estado = component.long_name
                                    break
                            }
                        }

                        
                    })
                    .catch(console.error);*/
                //setOptionSelected2((data.address.city || data.address.county || data.address.town) + ", " + data.address.state)
                //dispatch(setLocate((data.address.city || data.address.county || data.address.town) + ", " + data.address.state))
                //setLocation(data)
                setPosition(position.coords)
            }, err => {
                dispatch(setCoords({ lat: 0, lng: 0 }))
            })
        }
    }


    useEffect(async () => {
        const permission_state = await navigator.permissions.query({ name: 'geolocation' })
        if ((permission_state.state == "granted" || permission_state.state == "prompt")) {
            setTimeout(() => {
                getLocation()
            }, 1000)
        }

        if (actualPage == "resultados") {
            setPrincipalFlag(true)
        }
    }, [])

    const [emblaRef2, emblaApi2] = useEmblaCarousel({}, [
        Autoplay({ playOnInit: true, delay: 1000 })
    ])

    const slides = [
        {
            term: "Hospitales", icon: <FaBriefcaseMedical />,
            cat: "Hospitales, Sanatorios y Clinicas",
            svg: svgHospitales
        },
        {
            term: "Escuelas", icon: <IoSchoolSharp />,
            cat: "Escuelas, Institutos y Universidades",
            svg: svgEscuelas
        },
        {
            term: "Hoteles", icon: <MdHotel />,
            cat: "Hoteles",
            svg: svgHoteles
        },
        {
            term: "Abogados", icon: <GoLaw />,
            cat: "Abogados",
            svg: svgAbogados
        },
        {
            term: "Ferreterias", icon: <FaTools />,
            cat: "Ferreterias",
            svg: svgMecanicos
        },
        {
            term: "Laboratorios", icon: <ImLab />,
            cat: "Laboratorios de Diagnostico Clinico",
            svg: svgLaboratorios
        },
        {
            term: "Notarios", icon: <VscLaw />,
            cat: "Notarios",
            svg: svgNotario
        },
        {
            term: "Fumigaciones", icon: <GrUserWorker />,
            cat: "Fumigaciones",
            svg: svgFumigaciones
        },
        {
            term: "Construccion", icon: <FaBuilding />,
            cat: "Materiales para Construccion",
            svg: svgConstruccion
        },
        {
            term: "Aceros", icon: <GiMetalBar />,
            cat: "Aceros"
        },
    ]

    useEffect(() => {
        if (filtroOpened) {
            setLoginFlag(!loginFlag)
        }
    }, [filtroOpened])

    const { searchPage, openLoginForm } = useSelector(state => state.ui);
    useEffect(() => {
        if (openLoginForm) {
            setLoginFlag(true)
            dispatch(setOpenLoginForm(false))
        }
    }, [openLoginForm])

    useEffect(() => {
        if (searchPage != 0) {
            handleSubmit(searchPage)
        }
    }, [searchPage])

    const [value2, setValue2] = useState([20, 37])
    const [value1, setValue1] = useState(20)
    const [loginFlag, setLoginFlag] = useState(false)
    const [menuFlag, setMenuFlag] = useState(false)
    const [filtrosOpened, setFiltrosOpened] = useState(false)
    const minDistance = 10;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setMenuFlag(true)
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuFlag(false)
        setAnchorEl(null);
    };
    const handleChange2 = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], 100 - minDistance);
                setValue2([clamped, clamped + minDistance]);
            } else {
                const clamped = Math.max(newValue[1], minDistance);
                setValue2([clamped - minDistance, clamped]);
            }
        } else {
            setValue2(newValue);
        }
    }

    const handleChange = (event, newValue, activeThumb) => {
        setValue1(newValue)
    }

    const [colonia, setColonia] = useState(undefined)
    const [currentId, setCurrentId] = useState("")

    const [colonias, setColonias] = useState([
        { label: "Colonia 1", value: "Colonia 1" },
        { label: "Colonia 2", value: "Colonia 2" },
        { label: "Colonia 3", value: "Colonia 3" },
        { label: "Colonia 3", value: "Colonia 4" },
    ])
    const { height, width } = useWindowDimensions()
    const filtros = [
        <div className="form-check text-left">
            <p className='font-weight-bold m-0'>Calificación</p>
            <input className="form-check-input" type="checkbox" id="flexCheckChecked" />
            <label className="form-check-label" htmlFor="flexCheckChecked">
                <i data-star={4}></i> o más
            </label>
        </div>,
        <div>
            <p className='font-weight-bold m-0'>Precio</p>
            <p className='m-0'>${value2[0] + " - $" + value2[1]}</p>
            <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={value2}
                onChange={handleChange2}
                valueLabelDisplay="auto"
                disableSwap
            />
        </div>,
        <div>
            <p className='font-weight-bold m-0'>Distancia</p>
            <p className='m-0'>{value1}km</p>
            <Slider
                getAriaLabel={() => 'Minimum distance shift'}
                value={value1}
                onChange={handleChange}
                valueLabelDisplay="auto"
                disableSwap
            />
        </div>,
        <div>
            <Select
                options={colonias}
                value={colonia}
                autoFocus={false}
                isSearchable={true}
                onChange={(e) => {
                    setColonia(e)
                }}
                placeholder="Colonia"
            />
        </div>,
    ]

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3
        }
    }

    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 7
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 5
        }
    };

    const ref = useRef()

    const [carouselClicked, setCarouselClicked] = useState(false)

    useEffect(() => {
        if (carouselClicked && optionSelected.length != 0) {
            setCarouselClicked(false)
        }
    }, [carouselClicked, optionSelected])

    useEffect(() => {
        if (flag) {
            const id = width <= 768 ? "inputMobileCard" : currentId
            let int = setInterval(() => {
                if (document.getElementById(id)) {
                    document.getElementById(id).focus()
                }
                clearInterval(int)
            }, 100);
        }
        console.log(flag)
    }, [flag, currentId])

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                console.log("3")
                setFlag(flag ? false : flag)
            }
        };
        const handleClick = (e) => {
            if (e.target.id != "inputDesktopCard" && e.target.id != "inputDeskWhere" && width > 768) {
                console.log("2")
                //setFlag(flag ? false : flag)
            }
        }
        window.addEventListener('keydown', handleEsc);
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('keydown', handleEsc);
            window.removeEventListener('click', handleClick);
        };
    }, [])

    const [horarioMin, setHorarioMin] = useState(undefined)
    const [horarioMax, setHorarioMax] = useState(undefined)
    const [searchNumber, setSearchNumber] = useState(0)

    useEffect(() => {
        if (actualPage == "resultados" && !principalFlag && searchNumber == 0) {
            const splits = String(locationRoute.pathname).split("/")
            const term = splits[2]?.replace(/-/g, " ")
            const state = splits[3]?.replace(/-/g, " ")
            const city = splits[4]?.replace(/-/g, " ")
            const page = splits[splits.length - 1]?.replace(/-/g, " ")

            setOptionSelected(term.charAt(0).toUpperCase() + term.slice(1))
            if (city && state) {
                setOptionSelected2(city.charAt(0).toUpperCase() + city.slice(1) + ", " + state.charAt(0).toUpperCase() + state.slice(1))
            }

            if (optionSelected || optionSelected2) {
                handleSubmit(page)
            }
        }
    }, [actualPage, optionSelected, optionSelected2])

    const buscadorDesktop = <div className='row m-0 p-0 pl-3 rounded-pill shadow bg-white'
        id="buscadorDesktop" onClick={(e) => {
            setCurrentId(e.target.id)
            console.log("4")
            setFlag(true)
        }}>
        <div className='col-5 p-1'>
            <Autocomplete
                getItemValue={(item) => item.label}
                wrapperProps={{ style: { width: "100%", height: "100%" } }}
                items={optionsMap}
                renderItem={(item, isHighlighted) =>
                    <div style={{
                        background: isHighlighted ? '#fd0' : 'whitesmoke', maxWidth: "100%", zIndex: 1400,
                    }}
                        className='pl-2 pr-2'>
                        {item.label}
                    </div>
                }
                menuStyle={{
                    borderRadius: "15px", fontSize: "1.3rem", top: "40px", left: "0",
                    cursor: "pointer", position: "absolute", zIndex: 1400, background: "whitesmoke",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                }}
                value={optionSelected}
                onChange={(e) => {
                    handleInput(e.target.value, "term")
                    setOptionSelected(e.target.value)
                    dispatch(setTerm(e.target.value))
                }}
                inputProps={{
                    style: {
                        border: "0",
                        borderRadius: "0", background: "transparent",
                        fontSize: "1.3rem",
                        boxShadow: "0", outline: "none",
                        '&:hover': {}, cursor: "text", width: "100%", height: "100%"
                    }, placeholder: "¿Qué estás buscando?", className: "border-right",
                    onKeyUp: (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                            setOptionSelected(e.target.value)
                            dispatch(setTerm(e.target.value))
                        }
                    },
                    id: "inputDesktopCard"
                }}
                onSelect={(val) => {
                    setOptionSelected(val)
                    dispatch(setTerm(val))
                }}
            />
        </div>
        <div className='col-5 p-1'>
            <Autocomplete
                getItemValue={(item) => item.label}
                wrapperProps={{ style: { width: "100%", height: "100%" } }}
                items={optionsMap2}
                renderItem={(item, isHighlighted) =>
                    <div style={{
                        background: isHighlighted ? '#fd0' : 'whitesmoke', maxWidth: "100%",
                    }}
                        className='pl-2 pr-2'>
                        {item.label}
                    </div>
                }
                menuStyle={{
                    borderRadius: "0", fontSize: "1.3rem", top: "40px", left: "10px",
                    cursor: "pointer", position: "absolute", zIndex: 1400, background: "whitesmoke",
                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                }}
                value={optionSelected2}
                onChange={(e) => {
                    handleInputLoc(e.target.value, "place")
                    setOptionSelected2(e.target.value)
                    dispatch(setLocate(e.target.value))
                }}
                inputProps={{
                    style: {
                        border: "0",
                        borderRadius: "0", background: "transparent", fontSize: "1.3rem",
                        boxShadow: "0", outline: "none",
                        '&:hover': {}, cursor: "text", width: "100%", height: "100%"
                    }, placeholder: "¿En dónde?",
                    onKeyUp: (e) => {
                        if (e.key === 'Enter' || e.keyCode === 13) {
                            setOptionSelected2(e.target.value)
                            dispatch(setLocate(e.target.value))
                        }
                    },
                    id: "inputDeskWhere"
                }}
                onSelect={(val) => {
                    setOptionSelected2(val)
                    dispatch(setLocate(val))
                }}
            />
        </div>
        <div className='col-2 rounded-pill d-flex m-0 p-0' style={{ background: "linear-gradient(to left, black 70%, white 30%)" }}>
            <div className='bg-white w-50 rounded-pill text-right'>
                <button className='btn rounded-circle'
                    onClick={() => {
                        handleSubmit(1)
                    }}
                    disabled={!(optionSelected.length > 0 || optionSelected2.length > 0)}
                    style={{ backgroundColor: '#fd0', aspectRatio: "1 / 1" }}>
                    {loading ?
                        <Spinner size='md' className='p-0' animation="border" role="status"></Spinner> :
                        <><FiSearch style={{ fontSize: "24px" }} /></>}
                </button>
            </div>
            <div className='w-50 text-center rounded-pill' onClick={() => { }}
                style={{ fontSize: "18px", cursor: "pointer", }}>
                <button className='btn btn-block h-100 text-white'
                    style={{}}>
                    <LuSettings2 style={{ fontSize: "24px" }} />
                </button>
            </div>
        </div>
    </div>

    const [show, setShow] = useState(false)
    const handleClose = () => {
        setLoginFlag(false);
    };
    const handleCloseSearcher = () => {
        console.log("5")
        setFlag(false);
    };

    return (
        <>
            <ToastContainer />
            <div className={'row text-center noBorderOnMd'}
                style={{
                    height: "60px", backgroundSize: "0", position: "sticky", top: "0", zIndex: "1020",
                    //backgroundColor: isOpen ? "rgba(0,0,0,.1)" : "white",
                    backgroundColor: "white",
                    marginTop: (width > 768 && actualPage == undefined) && "50px",
                }}>
                <div className='col-2 col-md-3 pr-0' style={{ fontSize: "30px", paddingTop: "2px" }}>
                    {
                        locationRoute.pathname != "/" &&
                        <div className='text-center'>
                            <IoIosArrowBack className='d-inline d-md-none' onClick={() => {
                                if (actualPage == 'resultados') {
                                    handleChangeOfRoute("")
                                } else {
                                    history.goBack()
                                }
                            }} />
                        </div>
                    }
                    <div className='text-left ml-4'>
                        <img
                            src={logo}
                            className="d-none d-md-inline-block pl-3"
                            alt="seccionamarilla_logo"
                            ref={ref}
                            style={{
                                cursor: "pointer", maxHeight: "35px", maxWidth: "100%",
                                objectFit: "contain", width: "auto", height: "auto"
                            }}
                            onClick={() => { handleChangeOfRoute("") }}
                        />
                    </div>
                </div>
                <div className='col-8 col-md-7 m-0 p-0 text-center'>
                    <div className='pt-3 d-block d-md-none'>
                        <img
                            src={logo}
                            className="d-inline-block"
                            alt="seccionamarilla_logo"
                            height="35px"
                            ref={ref}
                            style={{ cursor: "pointer" }}
                            onClick={() => { handleChangeOfRoute("") }}
                        />
                    </div>
                    <div className='d-none d-md-inline-block mt-2 w-100'>
                        {buscadorDesktop}
                    </div>
                </div>
                <div className='col-2 d-flex flex-row-reverse'>
                    <div className='row shadow rounded-pill p-0 mr-3 d-none d-md-flex'
                        style={{ width: "fit-content" }}>
                        <div className='col-6' style={{
                            paddingTop: "15px", paddingRight: "15px", paddingLeft: "15px",
                            cursor: "pointer",
                        }}>
                            <div onClick={(e) => {
                                if (isLoggedIn()) {
                                    //handleClick(e)
                                    handleChangeOfRoute("usuario")
                                } else {
                                    setLoginFlag(true)
                                }
                            }}>
                                {
                                    JSON.parse(getCookie("user"))?.foto_url ?
                                        <img width={"30px"} src={JSON.parse(getCookie("user"))?.foto_url} className='rounded-circle' /> :
                                        <FaUser style={{ fontSize: "30px" }} />
                                }
                            </div>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl) && menuFlag}
                                onClose={handleCloseMenu}
                            >
                                <MenuItem onClick={() => {
                                    handleCloseMenu()
                                    handleChangeOfRoute("usuario")
                                }}>
                                    <FaRegUser />&nbsp;Mi perfil
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    handleCloseMenu()
                                    handleLogout()
                                    handleChangeOfRoute("")
                                }}>
                                    <IoIosLogOut />&nbsp;Cerrar sesión
                                </MenuItem>
                            </Menu>
                        </div>
                        <div className='col-6' style={{ padding: "5px" }}>
                            <Hamburger size={30} toggled={isOpen}
                                color={isOpen ? "#fd0" : "black"}
                                toggle={(e) => {
                                    setOpen(e)
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-block d-md-none' >
                <Zoom duration={200}>
                    <Collapse isOpened={isOpenCollapse}>
                        <div className='row mr-2 ml-2 mt-1 pt-0 p-1 bg-white' ref={ref} style={{
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            borderRadius: "10px"
                        }}>
                            <div className='col-12 col-md-6 mb-2 pt-2' onClick={(e) => {
                                console.log("6")
                                setFlag(!flag)
                                setShow(true)
                                setCurrentId(e.target.id)
                            }}>
                                <div className='row m-0 p-0 pl-3 rounded-pill shadow bg-white'>
                                    <div className='col-12 m-0 p-0 pt-3 pb-3 d-flex'>
                                        <div className='pr-2 d-flex align-items-center'>
                                            <img width={"20px"} src={svgBusqueda} />
                                        </div>
                                        <Autocomplete
                                            getItemValue={(item) => item.label}
                                            wrapperProps={{ style: { width: "100%" } }}
                                            items={optionsMap}
                                            renderItem={(item, isHighlighted) =>
                                                <div style={{
                                                    background: isHighlighted ? '#fd0' : 'whitesmoke', maxWidth: "100%",
                                                }}
                                                    className='pl-2 pr-2'>
                                                    {item.label}
                                                </div>
                                            }
                                            menuStyle={{
                                                borderRadius: "15px", fontSize: "1rem", top: "40px", left: "0",
                                                cursor: "pointer", position: "absolute", zIndex: 100, background: "whitesmoke",
                                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                                            }}
                                            value={optionSelected}
                                            onChange={(e) => {
                                                handleInput(e.target.value, "term")
                                                setOptionSelected(e.target.value)
                                                dispatch(setTerm(e.target.value))
                                            }}
                                            inputProps={{
                                                id: "inputMobile",
                                                tabIndex: -1,
                                                readOnly: true,
                                                style: {
                                                    border: "0",
                                                    borderRadius: "0", background: "transparent",
                                                    fontSize: "1.3rem",
                                                    boxShadow: "0", outline: "none",
                                                    '&:hover': {}, cursor: "text", width: "100%"
                                                }, placeholder: "Buscar",
                                                onKeyUp: (e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                                        setOptionSelected(e.target.value)
                                                        dispatch(setTerm(e.target.value))
                                                    }
                                                }
                                            }}
                                            onSelect={(val) => {
                                                setOptionSelected(val)
                                                dispatch(setTerm(val))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='row m-0 p-0 mt-3 shadow' style={{ borderRadius: "10px" }}>
                                    <div className='col-12 pt-3 m-0'>
                                        <p className='font-weight-bold border-bottom pb-2'>
                                            <IoLocationSharp />&nbsp; {optionSelected2 || "Tu ubicación"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {actualPage != undefined &&
                                <div className='col-12'>
                                    <Collapse isOpened={filtrosOpened}>
                                        <div className='row'>
                                            {filtros.map((v, idx) => (
                                                <div key={idx} className='col-6'>
                                                    {v}
                                                </div>
                                            ))}
                                        </div>
                                    </Collapse>
                                </div>}
                            {
                                actualPage == undefined &&
                                <div className='col-12 mt-3'>
                                    <Carousel arrows={false} autoPlay={false} responsive={responsive} rewind={true}
                                        rewindWithAnimation={true}>
                                        {slides.map((slide, idx) => (
                                            <Zoom key={idx} duration={200}>
                                                <div className='text-center d-flex justify-content-center' onClick={() => {
                                                    setOptionSelected(slide.cat)
                                                    dispatch(setTerm(slide.cat))
                                                    setCarouselClicked(true)
                                                }}>
                                                    <div className='d-flex align-items-center' style={{ fontSize: "30px", cursor: "pointer" }}>
                                                        {slide.svg ?
                                                            <img height={"30px"} src={slide.svg}></img> : slide.icon
                                                        }
                                                        <p className='p-0 m-0 ml-1 mr-1' style={{
                                                            fontSize: "14px", fontWeight: "bold",
                                                            whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"
                                                        }}>
                                                            {slide.term}
                                                        </p>
                                                    </div>
                                                    {optionSelected == slide.term &&
                                                        <div className="d-flex justify-content-center mt-2">
                                                            <Fade>
                                                                <div style={{ borderBottom: "3px solid #ffdd00", width: "30px" }}></div>
                                                            </Fade>
                                                        </div>
                                                    }
                                                </div>
                                            </Zoom>
                                        ))}
                                    </Carousel>
                                </div>
                            }
                        </div>
                    </Collapse>
                </Zoom>

            </div>
            {(actualPage == undefined || actualPage == "resultados") &&
                <div className='d-none d-md-flex justify-content-center p-2'
                    style={{ marginBottom: (width > 768 && actualPage == undefined) && "50px" }}>
                    {false &&
                        <div className='mt-4' style={{ maxWidth: "800px", margin: "0 auto" }}>
                            {buscadorDesktop}
                        </div>}
                    <div style={{ width: "80%" }}>
                        <Carousel arrows={false} autoPlay={true} responsive={responsive1} rewind={true}
                            rewindWithAnimation={true}>
                            {slides.map((slide, idx) => (
                                <div key={idx} className='text-center' onClick={() => {
                                    setOptionSelected(slide.cat)
                                    dispatch(setTerm(slide.cat))
                                }} style={{ fontSize: "35px", cursor: "pointer" }}>
                                    {slide.svg ?
                                        <img width={"35px"} src={slide.svg}></img> : slide.icon
                                    }
                                    <p className='p-0 m-0' style={{ fontSize: "14px" }}>
                                        {slide.term}
                                    </p>
                                    {optionSelected == slide.term &&
                                        <div className="d-flex justify-content-center mt-2">
                                            <Fade>
                                                <div style={{ borderBottom: "3px solid #ffdd00", width: "30px" }}></div>
                                            </Fade>
                                        </div>
                                    }
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            }
            {
                !reloading &&
                <div className={width > 1400 ? 'container' : ""}>
                    {
                        (actualPage == undefined) &&
                        /*<Suspense
                            fallback={
                                <div>
                                    Cargando...
                                </div>
                            }
                        >
                            <div className={width > 1400 ? 'd-flex justify-content-center' : ""}>
                                <InicioScreen />
                            </div>
                        </Suspense>*/
                        <InicioScreen />
                    }
                    {
                        (actualPage == 'resultados') &&
                        <ResultadoScreen info={info} />
                    }
                    {
                        (actualPage == 'favoritos') &&
                        <FavoriteScreen info={info} />
                    }
                    {
                        (actualPage == 'usuario') &&
                        <UsuarioScreen info={info} />
                    }
                    {
                        (actualPage == 'informacion') &&
                        <InformacionScreen info={info} />
                    }
                </div>
            }
            <div className='bg-black row p-3'>
                <div className="col-12 col-md-4 mt-3">
                    <a className='text-white d-inline-block mw-150'><u>Nuestras oficinas</u></a><br />
                    <a className='text-white d-inline-block mw-150'><u>Proveedores</u></a>
                </div>
                <div className="col-12 col-md-4 mt-3">
                    <a className='text-white d-inline-block mw-150'><u>Atracción de talento</u></a><br />
                    <a className='text-white d-inline-block mw-150'><u>Términos y condiciones</u></a>
                </div>
                <div className="col-12 col-md-4 mt-3">
                    <a className='text-white d-inline-block mw-150'><u>Aviso de privacidad</u></a><br />
                    <a className='text-white d-inline-block mw-150'><u>Términos y condiciones Tienda ADN</u></a>
                </div>
                <div className='col-12 mt-50 pl-4 pr-4 text-center' style={{ marginBottom: width <= 768 ? "120px" : "" }}>
                    <img src={footerlinks} className='mw-150' />
                </div>
            </div>
            <Dialog
                open={loginFlag}
                maxWidth={width > 768 && "md"}
                TransitionComponent={width <= 768 ? Transition : TransitionDsk}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        borderRadius: "15px"
                    },
                    sx: {
                        mt: "60px",
                        verticalAlign: "top"
                    }
                }}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            //maxWidth: "500px",
                        },
                    },
                }}
                scroll="body"
            >
                <DialogTitle id="id" className='d-flex p-0 m-0'>
                    <div className=''>
                        <IconButton onClick={() => {
                            setLoginFlag(false)
                        }}>
                            <FaTimes />
                        </IconButton>
                    </div>
                </DialogTitle>
                <LoginScreen />
            </Dialog>
            <Dialog
                fullScreen={width > 768 ? false : true}
                open={flag}
                maxWidth={width > 768 && "md"}
                TransitionComponent={width <= 768 ? Transition : TransitionDsk}
                disableEnforceFocus
                style={{
                    pointerEvents: 'none',
                    zIndex: 1000
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        zIndex: 1000
                    },
                }}
                onClose={handleCloseSearcher}
                scroll="body"
                PaperProps={{
                    style: {
                        pointerEvents: 'auto',
                        borderRadius: "15px",
                    },
                    sx: {
                        mt: (document.getElementById("buscadorDesktop")?.getBoundingClientRect().top + 60) + "px",
                        verticalAlign: "top"
                    }
                }}
            >
                {width <= 768 &&
                    <div className='row m-0'>
                        <div className='col-12 text-center border-bottom pt-3 ' style={{ height: "60px" }}>
                            <img
                                src={logo}
                                className="d-inline-block"
                                alt="seccionamarilla_logo"
                                style={{
                                    cursor: "pointer", maxHeight: "35px", maxWidth: "100%",
                                    objectFit: "contain", width: "auto", height: "auto"
                                }}
                                onClick={() => { handleChangeOfRoute("") }}
                            />
                        </div>
                    </div>
                }
                <div className='row mr-2 ml-2 mt-1 pt-0 p-1 bg-white' style={{
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "10px"
                }}>
                    {width <= 768 &&
                        <>
                            <div className='col-12 col-md-6 mb-2 pt-2'>
                                <div className='row m-0 p-0 pl-3 rounded-pill shadow bg-white'>
                                    <div className='col-10 m-0 p-0 pt-2 pb-2' onClick={(e) => { }}>
                                        <Autocomplete
                                            getItemValue={(item) => item.label}
                                            wrapperProps={{ style: { width: "100%" }, id: "autocomplete" }}
                                            items={optionsMap}
                                            renderItem={(item, isHighlighted) =>
                                                <div
                                                    style={{
                                                        background: isHighlighted ? '#fd0' : 'whitesmoke', maxWidth: "100%"
                                                    }} className={'pl-2 pr-2' + (flag ? "" : " d-none")}>
                                                    {item.label}
                                                </div>
                                            }
                                            menuStyle={{
                                                borderRadius: "15px", fontSize: "1.3rem", top: "40px", left: "0",
                                                cursor: "pointer", position: "absolute", zIndex: 100, background: "whitesmoke",
                                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                                            }}
                                            value={optionSelected}
                                            onChange={(e) => {
                                                handleInput(e.target.value, "term")
                                                setOptionSelected(e.target.value)
                                                dispatch(setTerm(e.target.value))
                                            }}
                                            inputProps={{
                                                id: "inputMobileCard",
                                                style: {
                                                    border: "0",
                                                    borderRadius: "0", background: "transparent",
                                                    fontSize: "1.3rem",
                                                    boxShadow: "0", outline: "none",
                                                    '&:hover': {}, cursor: "text", width: "100%"
                                                }, placeholder: "¿Qué estás buscando?",
                                                onKeyUp: (e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                                        setOptionSelected(e.target.value)
                                                        dispatch(setTerm(e.target.value))
                                                    }
                                                }
                                            }}
                                            onSelect={(val) => {
                                                setOptionSelected(val)
                                                dispatch(setTerm(val))
                                            }}
                                        />
                                    </div>
                                    <div className='col-2 m-0 p-0 text-center rounded-pill'
                                        style={{ fontSize: "18px", cursor: "pointer", backgroundColor: "#fd0" }}>
                                        <button className='btn btn-block h-100'
                                            onClick={() => {
                                                handleSubmit(1)
                                            }}
                                            disabled={!(optionSelected.length > 0 || optionSelected2.length > 0)}
                                            style={{}}>
                                            {loading ?
                                                <Spinner size='sm' animation="border" style={{ padding: "0px" }} role="status"></Spinner> :
                                                <><FaSearch></FaSearch></>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6 mb-3 pt-2'>
                                <div className='row m-0 p-0 shadow rounded-pill' style={{ background: "linear-gradient(to right, white 75%, black 25%)" }}>
                                    <div className='col-10 m-0 pl-3 p-0 pt-2 pb-2 rounded-pill bg-white'>
                                        <Autocomplete
                                            getItemValue={(item) => item.label}
                                            wrapperProps={{ style: { width: "100%" } }}
                                            items={optionsMap2}
                                            renderItem={(item, isHighlighted) =>
                                                <div style={{
                                                    background: isHighlighted ? '#fd0' : 'whitesmoke', maxWidth: "100%",
                                                }}
                                                    className='pl-2 pr-2'>
                                                    {item.label}
                                                </div>
                                            }
                                            menuStyle={{
                                                borderRadius: "0", fontSize: "1.3rem", top: "40px", left: "10px",
                                                cursor: "pointer", position: "absolute", zIndex: 100, background: "whitesmoke",
                                                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                                            }}
                                            value={optionSelected2}
                                            onChange={(e) => {
                                                handleInputLoc(e.target.value, "place")
                                                setOptionSelected2(e.target.value)
                                                dispatch(setLocate(e.target.value))
                                            }}
                                            inputProps={{
                                                style: {
                                                    border: "0",
                                                    borderRadius: "0", background: "transparent", fontSize: "1.3rem",
                                                    boxShadow: "0", outline: "none",
                                                    '&:hover': {}, cursor: "text", width: "100%"
                                                }, placeholder: "¿En dónde?",
                                                onKeyUp: (e) => {
                                                    if (e.key === 'Enter' || e.keyCode === 13) {
                                                        setOptionSelected2(e.target.value)
                                                        dispatch(setLocate(e.target.value))
                                                    }
                                                }
                                            }}
                                            onSelect={(val) => {
                                                setOptionSelected2(val)
                                                dispatch(setLocate(val))
                                            }}
                                        />
                                    </div>
                                    <div className='col-2 m-0 p-0 text-center rounded-pill' onClick={() => { }}
                                        style={{ fontSize: "18px", cursor: "pointer", backgroundColor: "black" }}>
                                        <button className='btn btn-block h-100 text-white'
                                            onClick={() => {
                                                setFiltrosOpened(!filtrosOpened)
                                            }}>
                                            <LuSettings2 style={{ fontSize: "24px" }} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className='col-12 col-md-6 mb-2 pt-2'>
                        <p className='p-0 m-0 mb-2' style={{ fontWeight: "bold", fontSize: "18px" }}>Categoría</p>
                        <p className='text-muted p-0 m-0 mb-3' style={{ fontSize: "10px" }}>Categorías populares</p>
                        <Carousel arrows={false} autoPlay={true} responsive={responsive} rewind={true}
                            rewindWithAnimation={true} className='mb-3'>
                            {slides.map((slide, idx) => (
                                <div key={idx} className='text-center d-flex justify-content-center' onClick={() => {
                                    setExplicitCategory(slide.term)
                                    dispatch(setTerm(slide.term))
                                    setCarouselClicked(true)
                                }} style={{ fontSize: "35px", cursor: "pointer" }}>
                                    <div className='d-flex align-items-center' style={{ fontSize: "30px", cursor: "pointer" }}>
                                        {slide.svg ?
                                            <img height={"30px"} src={slide.svg}></img> : slide.icon
                                        }
                                        <p className='p-0 m-0 ml-1 mr-1' style={{
                                            fontSize: "14px", fontWeight: "bold",
                                            whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"
                                        }}>
                                            {slide.term}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                        <div className='m-1'>
                            <Autocomplete
                                getItemValue={(item) => item.label}
                                wrapperProps={{ style: { width: "100%" } }}
                                items={optionsMap}
                                renderItem={(item, isHighlighted) =>
                                    <div style={{
                                        background: isHighlighted ? '#fd0' : 'whitesmoke', maxWidth: "100%",
                                    }}
                                        className='pl-2 pr-2'>
                                        {item.label}
                                    </div>
                                }
                                menuStyle={{
                                    borderRadius: "0", fontSize: "1.3rem",
                                    cursor: "pointer", background: "whitesmoke",
                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                                }}
                                value={explicitCategory}
                                onChange={(e) => {
                                    handleInput(e.target.value, "place")
                                    setExplicitCategory(e.target.value)
                                    //dispatch(setLocate(e.target.value))
                                }}
                                inputProps={{
                                    className: "form-control form-control-lg",
                                    style: {
                                        background: "transparent",
                                        boxShadow: "0", outline: "none",
                                        '&:hover': {}, cursor: "text", width: "100%"
                                    }, placeholder: "Hospitales, Comida, Entretenimiento...",
                                    onKeyUp: (e) => {
                                        if (e.key === 'Enter' || e.keyCode === 13) {
                                            setExplicitCategory(e.target.value)
                                        }
                                    }
                                }}
                                onSelect={(val) => {
                                    setExplicitCategory(val)
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-12 col-md-6 mb-2 pt-2'>
                        <p className='p-0 m-0 mb-2' style={{ fontWeight: "bold", fontSize: "18px" }}>Rango de precios</p>
                        <p className='text-muted p-0 m-0' style={{ fontSize: "12px" }}>Precios antes de impuestos</p>
                        <div className='row'>
                            <div className='col'>
                                <p className='text-muted p-0 m-0' style={{ fontSize: "12px" }}>Mínimo</p>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text bg-white">$</span>
                                    </div>
                                    <input className='form-control form-control-lg' value={value2[0]} onChange={() => { }} type='number' />
                                </div>
                            </div>

                            <div className='col-1 p-0 m-0' style={{ alignItems: "center", display: "flex" }}>
                                <hr style={{ width: "100%", color: "black" }} />
                            </div>

                            <div className='col'>
                                <p className='text-muted p-0 m-0' style={{ fontSize: "12px" }}>Máximo</p>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text bg-white">$</span>
                                    </div>
                                    <input className='form-control form-control-lg' value={value2[1]} onChange={() => { }} type='number' />
                                </div>
                            </div>
                        </div>
                        <Slider
                            getAriaLabel={() => 'rango de precios'}
                            value={value2}
                            onChange={handleChange2}
                            valueLabelDisplay="auto"
                            disableSwap
                            style={{
                                color: "#ffdd00"
                            }}
                        />
                        <p className='p-0 m-0 mb-2' style={{ fontWeight: "bold", fontSize: "18px" }}>Horarios</p>
                        <div className='row'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className='col'>
                                    <p className='text-muted p-0 m-0' style={{ fontSize: "12px" }}>De</p>
                                    <TimePicker value={horarioMin} maxTime={horarioMax}
                                        onChange={(newValue) => {
                                            setHorarioMin(newValue)
                                        }} defaultValue={dayjs('2022-04-17T09:00')} />
                                </div>

                                <div className='col-1 p-0 m-0' style={{ alignItems: "center", display: "flex" }}>
                                    <hr style={{ width: "100%", color: "black" }} />
                                </div>

                                <div className='col'>
                                    <p className='text-muted p-0 m-0' style={{ fontSize: "12px" }}>Hasta</p>
                                    <TimePicker value={horarioMax} minTime={horarioMin}
                                        onChange={(newValue) => setHorarioMax(newValue)} defaultValue={dayjs('2022-04-17T17:00')} />
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
                <div className='row w-100 m-0 mb-3' style={width <= 768 ? {
                    position: "fixed", bottom: "25px",
                    borderTopRightRadius: "15px", borderTopLeftRadius: "15px", boxShadow: "0px -4px 3px rgba(0,0,0,.1)"
                } : {}}>
                    <div className='col-6 mt-3'>
                        <button href="#" className="btn btn-outline-dark rounded-pill btn-block"
                            style={{
                                fontWeight: "bold",
                                fontSize: "20px"
                            }} onClick={() => {
                                console.log("7")
                                setFlag(false)
                                setTimeout(() => {
                                    setShow(false)
                                }, 100);
                            }}>
                            Cancelar
                        </button>
                    </div>
                    <div className='col-6 mt-3'>
                        <button className="btn rounded-pill btn-block"
                            style={{
                                fontWeight: "bold", color: "white",
                                fontSize: "20px", backgroundColor: "#ffdd00"
                            }}
                            disabled={!(optionSelected.length > 0 || optionSelected2.length > 0)}
                            onClick={() => {
                                handleSubmit(1)
                            }}
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </Dialog>

            {!flag &&
                <div className='d-block d-md-none text-center'>
                    <div className='d-flex justify-content-center'>
                        <div className='d-flex border-top' style={{
                            height: "60px", backgroundSize: "0",
                            width: ((width - 40) + "px"), position: "fixed", bottom: "40px", zIndex: "1020",
                            backgroundColor: "white", maxWidth: "100%", transition: "height 0.5s ease-in",
                            borderRadius: "15px", boxShadow: "0 0 15px rgba(0,0,0,.5)"
                        }}>
                            <div className='col-3 text-center fs-35'
                                onClick={() => {
                                    console.log(width + "px - calc(32 / 16 * 1rem)")
                                    ref?.current?.scrollIntoView({ block: 'end', behavior: 'smooth' })
                                    handleChangeOfRoute("")
                                }}>
                                {actualPage != undefined ?
                                    <GoHome /> :
                                    <GoHomeFill />
                                }
                            </div>
                            <div className='col-3 text-center fs-35'>
                                <IoIosSearch onClick={() => {
                                    console.log("8")
                                    setFlag(true)
                                }} />
                            </div>
                            <div className='col-3 text-center fs-35'
                                onClick={() => {
                                    if (isLoggedIn()) {
                                        handleChangeOfRoute("favoritos")
                                    } else {
                                        setLoginFlag(true)
                                    }
                                }}>
                                {actualPage != "favoritos" ?
                                    <IoMdHeartEmpty /> :
                                    <IoMdHeart />
                                }
                            </div>
                            <div className='col-3 text-center fs-35' onClick={() => {
                                if (isLoggedIn()) {
                                    handleChangeOfRoute("usuario")
                                } else {
                                    setLoginFlag(true)
                                }
                            }}>
                                {
                                    JSON.parse(getCookie("user"))?.foto_url ?
                                        <img width={"30px"} src={JSON.parse(getCookie("user"))?.foto_url}
                                            className={'rounded-circle' + (actualPage != "usuario" ? "" : " border-dark-2")} /> :
                                        <>
                                            {
                                                actualPage != "usuario" ?
                                                    <FaRegUser /> : <FaUser />
                                            }
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}
