import { types } from '../types/types';

const initialState = {
    loading: false,
    msgError: null,
    searchPage: false,
    openLoginForm: false,
    term: "",
    location: "",
    info: [],
    currentBusiness: {},
    filtroOpened: false,
    searchOpened: false
}


export const uiReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.uiSetError:
            return {
                ...state,
                msgError: action.payload
            }

        case types.uiRemoveError:
            return {
                ...state,
                msgError: null
            }

        case types.uiStartLoading:
            return {
                ...state,
                loading: true
            }

        case types.uiFinishLoading:
            return {
                ...state,
                loading: false
            }
        case types.filtroClosed:
            return {
                ...state,
                filtroOpened: false
            }
        case types.filtroOpened:
            return {
                ...state,
                filtroOpened: true
            }
        case "location":
            return {
                ...state,
                location: action.payload
            }
        case "openLoginForm":
            return {
                ...state,
                openLoginForm: action.payload
            }
        case "page":
            return {
                ...state,
                searchPage: action.payload
            }
        case "coords":
            return {
                ...state,
                coords: action.payload
            }
        case "term":
            return {
                ...state,
                term: action.payload
            }
        case "info":
            return {
                ...state,
                info: action.payload
            }
        case "currentBusiness":
            return {
                ...state,
                currentBusiness: action.payload
            }
        case "params":
            return {
                ...state,
                params: action.payload
            }
        default:
            return state;
    }

}
