import React from 'react';
import { Provider } from 'react-redux';

import { store } from './store/store';
import { AppRouter } from './routers/AppRouter';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const App = (props) => {
  //console.log(props)
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <AppRouter info={{ ...props.info }} />
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
