import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    startLoginEmailPassword,
    startLoginGoogle
} from '../../actions/auth';
import usuario from '../../imgs/icons/icon-usuario.svg'
import { FaEye, FaEyeSlash, FaKey, FaUser } from 'react-icons/fa';
import { useWindowDimensions } from '../../helpers/hooks';
import { validateEmail, validatePassword, validateZipCode } from '../../helpers/validators';
import { makePOST } from '../../helpers/makeRequest';
import Swal from 'sweetalert2';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { filterClosed, filterOpened } from '../../actions/ui';
import "../../styles/login-styles.css";

export const LoginScreen = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.ui);
    const [passShowedLogin, setPassShowedLogin] = useState(false)
    const [passShowedLoginConf, setPassShowedLoginConf] = useState(false)
    const [passError, setPassError] = useState("")
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConf, setPasswordConf] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [zipCode, setZipCode] = useState('')

    const handleLogin = async (e) => {
        e.preventDefault();
        dispatch(startLoginEmailPassword(email, password))
    }

    const { height, width } = useWindowDimensions()
    const [registerFlag, setRegisterFlag] = useState(false)
    const [forgottenFlag, setForgottenFlag] = useState(false)

    const isNumberKey = (evt) => {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return charCode

        return charCode
    }
    const handleRegister = async () => {
        if (validateForm().length == 0) {
            const resp = await makePOST("auth/register", {
                email, password, first_name: firstName, last_name: lastName, zip_code: zipCode
            })
            if (resp.status == 200) {
                Swal.fire('Éxito!', 'Su cuenta ha sido registrada correctamente. Consulte su correo electrónico para verificarla.', 'success');
                toast.success('Éxito! Su cuenta fue registrada correctamente. Consulte su correo para verificarla.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                dispatch(filterOpened())
                dispatch(filterClosed())
            } else {
                Swal.fire('Error', 'Registro fallido. Vuelva a intentarlo más tarde.', 'error');
            }
        }
    }

    const handleReset = async () => {
        if (validateEmail(email)) {
            const resp = await makePOST("auth/requestPasswordReset", { email })
            if (resp.status == 200) {
                toast.success('Solicitud de reinicio completado. Consulte su correo electrónico para reiniciarla.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                dispatch(filterOpened())
                dispatch(filterClosed())
            } else {
                Swal.fire('Error', 'Registro fallido. Vuelva a intentarlo más tarde.', 'error');
            }
        }
    }

    const handleGoogleLogin = async (credential) => {
        dispatch(startLoginGoogle(credential))
    }

    const validateForm = () => {
        if (registerFlag) {
            if (!validateEmail(email)) {
                return "Introduce un correo válido"
            }
            if ((password != passwordConf)) {
                return "Las contraseñas no coinciden"
            }
            if (!(validatePassword(password) && validatePassword(passwordConf))) {
                return "La contraseñas deben ser mayores a 10 caracteres, contener al menos una mayúscula y al menos un número."
            }
            if (firstName.length == 0) {
                return "Hace falta tu nombre"
            }
            if (lastName.length == 0) {
                return "Hacen falta tus apellidos"
            }
            if (!validateZipCode(zipCode)) {
                return "Introduce un código postal válido"
            }
        } else {
            if (!validateEmail(email)) {
                return "Introduce un correo válido"
            }
            if (!(validatePassword(password)) && !forgottenFlag) {
                return "La contraseña debe ser mayor a 10 caracteres, contener al menos una mayúscula y al menos un número."
            }
        }
        return ""
    }

    useEffect(() => {
        setPassError(validateForm())
    }, [password, passwordConf, registerFlag, firstName, lastName, zipCode, email])

    return (
        <>
            <div className="container-fluid h-100 d-flex justify-content-center">
                <div style={{ maxWidth: "450px", minHeight: height / 2 }}>
                    <div className="form-outline mb-5 mt-5 text-center">
                        {<img src={usuario} style={{ width: "40px", marginBottom: "30px" }} className="img-fluid" alt="Task logo" />}
                        <h4 className='h3 font-weight-bold text-white'>Registrate o Inicia sesión</h4>
                        <p className='text-white'>Al registrarte aceptas los Terminos de servicio de Sección Amarilla y su política de privacidad</p>
                    </div>
                    {(!registerFlag && !forgottenFlag) ?
                        <div className='text-center text-lg-start mt-4 mb-2 pt-2'>
                            <p>
                                ¿Aún no tienes una cuenta?&nbsp;
                                <a className="link-opacity-100-hover text-dark font-weight-bolder text-decoration-none" onClick={() => { setRegisterFlag(!registerFlag) }}
                                    role='button'>¡Regístrate!</a>
                            </p>
                        </div> :
                        <div className='text-center text-lg-start mt-4 mb-2 pt-2 border-top'>
                            <p>
                                ¿Ya tienes una cuenta?&nbsp;
                                <a className="link-opacity-100-hover font-weight-bolder text-decoration-none" onClick={() => {
                                    setRegisterFlag(false)
                                    setForgottenFlag(false)
                                }} role='button'>Inicia sesión</a>
                            </p>
                        </div>
                    }
                    <div className="text-center mt-4 mb-5 pt-2 d-flex justify-content-center">
                        <div>
                            <GoogleLogin
                                onSuccess={({ credential }) => {
                                    handleGoogleLogin(credential)
                                }}
                                locale='es_MX'
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Email"
                            name="email"
                            autoCapitalize="off"
                            autoCorrect="off"
                            className="form-control form-control-lg"
                            autoComplete="off"
                            value={email}
                            onChange={e => setEmail(e.target.value)} />
                    </div>
                    {!forgottenFlag &&
                        <div className="form-outline mb-3 text-center">
                            <div className="input-group mb-3 mt-4">
                                <input
                                    type={passShowedLogin ? "text" : "password"}
                                    placeholder="Contraseña"
                                    name="password"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                    className="form-control form-control-lg"
                                    value={password}
                                    onChange={e => { setPassword(e.target.value) }} />
                                <div className="input-group-append">
                                    <button className="btn" type="button"
                                        onClick={() => setPassShowedLogin(!passShowedLogin)}>
                                        {passShowedLogin ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                            </div>
                            {(passError.length > 0 && !registerFlag) &&
                                <span className='text-danger'>
                                    {passError}
                                </span>
                            }
                        </div>
                    }
                    {registerFlag &&
                        <>
                            <div className="form-outline mb-3 text-center">
                                <div className="input-group mb-3">
                                    <input
                                        type={passShowedLoginConf ? "text" : "password"}
                                        placeholder="Confirmar contraseña"
                                        name="password"
                                        autoCapitalize="off"
                                        autoCorrect="off"
                                        className="form-control form-control-lg form-line"
                                        value={passwordConf}
                                        onChange={e => setPasswordConf(e.target.value)} />
                                    <div className="input-group-append">
                                        <button className="btn" type="button"
                                            onClick={() => setPassShowedLoginConf(!passShowedLoginConf)}>
                                            {passShowedLoginConf ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-outline mb-3 text-center">
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        placeholder="Nombre"
                                        name="firstName"
                                        autoCapitalize="on"
                                        autoCorrect="off"
                                        className="form-control form-control-lg"
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-outline mb-3 text-center">
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        placeholder="Apellidos"
                                        name="lastName"
                                        autoCapitalize="on"
                                        autoCorrect="off"
                                        className="form-control form-control-lg"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)} />
                                </div>
                            </div>
                            <div className="form-outline mb-3 text-center">
                                <div className="input-group mb-3">
                                    <input
                                        type="number"
                                        placeholder="Código postal"
                                        name="zipCode"
                                        max={9999}
                                        autoCapitalize="on"
                                        autoCorrect="off"
                                        className="form-control form-control-lg"
                                        value={zipCode.replace(/\D/g, "")}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={e => {
                                            const value = e.target.value.replace(/\D/g, "");
                                            setZipCode(value.substring(0, 5));
                                        }} />
                                </div>
                            </div>
                            {passError.length > 0 &&
                                <span className='text-danger'>
                                    {passError}
                                </span>
                            }
                        </>
                    }
                    <div className="text-center text-lg-start mt-4 mb-2 pt-2">
                        <button className="btn btn-login" onClick={(e) => {
                            registerFlag ? handleRegister() : (forgottenFlag ? handleReset(e) : handleLogin(e))
                        }} disabled={validateForm().length != 0}>
                            {registerFlag ? "Registrarse" : (forgottenFlag ? "Solicitar reinicio" : "Iniciar sesión")}
                        </button>
                    </div>

                    {(!registerFlag && !forgottenFlag) &&
                        <div className='text-center text-lg-start mt-4 mb-2 pt-2'>
                            <p>
                                <a className="link-opacity-100-hover text-dark text-decoration-none" role='button'
                                    onClick={() => { setForgottenFlag(!forgottenFlag) }}>
                                    ¿Olvidaste tu contraseña?
                                </a>
                            </p>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}