export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const validatePassword = (password) => {
    return /[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && password.length > 10;
}

export const validateZipCode = (zipcode) => {
    return /^\d{5}/.test(zipcode);
}
