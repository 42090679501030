import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, } from 'react-redux';
import { FaRegCheckCircle, FaStar, FaTrash } from "react-icons/fa";
import { useWindowDimensions } from '../../helpers/hooks';
import { getCookie, setCookie } from '../../helpers/cookies';
import { Slide } from '@mui/material';
import { makeDELETE, makeGET } from '../../helpers/makeRequest';
import 'react-toastify/dist/ReactToastify.css';
import prueba1 from '../../imgs/prueba1.webp'
import prueba2 from '../../imgs/prueba2.webp'
import prueba3 from '../../imgs/prueba3.webp'
import prueba4 from '../../imgs/prueba4.avif'
import "../../styles/favourite-styles.css";
import { toast } from 'react-toastify';

export const FavoriteScreen = (props) => {
    const params = useParams()
    const { actualPage } = params
    const dispatch = useDispatch();

    const history = useHistory()
    const [page, setPage] = useState(actualPage == ':actualPage' ? 'inicio' : actualPage)
    const handleChangeOfRoute = (route) => {
        setPage(route)
        const href = window.location.href.includes("?") ? window.location.href.substring(window.location.href.indexOf("?")) : ""
        history.push('/' + route + href)
    }
    const [data, setData] = useState([])

    const { height, width } = useWindowDimensions()

    const getFavoritos = async (page) => {
        const { data } = await makeGET("favourites/list?page=" + page)
        setData([...data])
    }

    const [locations, setLocations] = useState([
        {
            ubicacion: "Los Mochis",
            img: prueba1,
            score: 3.5,
            favorito: true,
            lugares: 100,
            route: "sinaloa/los-mochis/1"
        },
        {
            ubicacion: "Puerto Vallarta",
            img: prueba2,
            score: 5,
            favorito: false,
            lugares: 85,
            route: "jalisco/puerto-vallarta/1"
        },
        {
            ubicacion: "Oaxaca",
            img: prueba3,
            score: 4.2,
            favorito: false,
            lugares: 74,
            route: "oaxaca/oaxaca-de-juarez/1"
        },
        {
            ubicacion: "Tepic",
            img: prueba4,
            score: 2.3,
            favorito: true,
            lugares: 70,
            route: "nayarit/tepic/1"
        },
        {
            ubicacion: "Colima",
            img: prueba1,
            score: 3.5,
            favorito: false,
            lugares: 50,
            route: "colima/colima/1"
        },
        {
            ubicacion: "Acapulco",
            img: prueba2,
            score: 5,
            favorito: true,
            lugares: 20,
            route: "guerrero/acapulco-de-juarez/1"
        },
        {
            ubicacion: "Pachuca",
            img: prueba3,
            score: 4.2,
            favorito: false,
            lugares: 10,
            route: "hidalgo/pachuca-de-soto/1"
        },
    ])

    useEffect(async () => {
        getFavoritos(1)
    }, [])

    const deleteFav = async (v, idx) => {
        if (getCookie("token")) {
            await makeDELETE("favourites/delete", { id_favourite: v?.favourite_info?.id_favourite })

            toast.success('Favorito eliminado', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            })

            const tmp = [...data]
            tmp.splice(idx, 1)
            setData([...tmp])
        }
    }

    return (
        <div className='row p-3' style={{
            borderRadius: "10px", width: width > 1400 ? "80%" : "",
            height: width > 768 ? ((height - 60) + "px") : "auto"
        }}>
            <div className='col-12 col-md-4 text-center'>
                <div className='col-12 mb-3'>
                    <p className='h3'>Tus favoritos</p>
                </div>
            </div>
            <div className='col-12 col-md-8 text-center border-left'>
                <div className='row'>
                    {data.map((v, i) => (
                        <div key={i} className='bg-white col-12 col-md-6' role='button'>
                            <div className='row m-0 p-0'>
                                <div className='col-6 m-0 p-2'
                                //onTouchStart={(e) => onSlideClick(false)}
                                >
                                    <div className='card-img-top position-relative'>
                                        {
                                            <FaRegCheckCircle className='second-veryfybg-home' />
                                        }
                                        {
                                            <div className='delete-icon'>
                                                <FaTrash className={"h3"} color='red' role='button'
                                                    onClick={() => {
                                                        deleteFav(v, i)
                                                    }} />
                                            </div>
                                        }
                                        <img className="w-100 p-2 img-top-home" key={i}
                                            onClick={() => {
                                                handleChangeOfRoute("informacion/" + v?.nombre.toLowerCase().replace(/ /g, '-')
                                                    + "/" + v?.categoria.toLowerCase().replace(/ /g, '-') + "/" + v?.listadoid)
                                            }}
                                            src={locations[i == 0 ? i % 4 : i]?.img} alt="Card image cap" />
                                    </div>
                                </div>
                                <div className='col-6 pl-0 pt-3'
                                    onClick={() => {
                                        handleChangeOfRoute("informacion/" + v?.nombre.toLowerCase().replace(/ /g, '-')
                                            + "/" + v?.categoria.toLowerCase().replace(/ /g, '-') + "/" + v?.listadoid)
                                    }}>
                                    <div className='text-left'>
                                        <span className="card-title p-0 m-0 h5 wrapText">{v?.nombre}</span>
                                        {v?.distancia ?
                                            <p className="card-text m-0 fs-12">
                                                A {v?.distancia} km de ti
                                            </p> : <></>
                                        }
                                        <p className='p-0 m-0 font-star-home'>
                                            <FaStar color="yellow" /> {v?.score}<br />
                                        </p>
                                        <span className='reviews-home'> (23 reviews)</span><br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </div>
        </div>
    )
}
