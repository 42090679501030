import { types } from '../types/types';

export const setTerm = ( payload ) => ({
    type: "term",
    payload
})

export const setLocate = ( payload ) => ({
    type: "location",
    payload
})

export const setSearchPage = ( payload ) => ({
    type: "page",
    payload
})

export const setOpenLoginForm = ( payload ) => ({
    type: "openLoginForm",
    payload
})

export const setCoords = ( payload ) => ({
    type: "coords",
    payload
})

export const setBusinessInfo = ( payload ) => ({
    type: "info",
    payload
})

export const setCurrentBusiness = ( payload ) => ({
    type: "currentBusiness",
    payload
})

export const setParams = ( payload ) => ({
    type: "params",
    payload
})

export const setError = ( err ) => ({
    type: types.uiSetError,
    payload: err
});

export const removeError = () => ({
    type: types.uiRemoveError
});

export const startLoading = () => ({
    type: types.uiStartLoading
})
export const finishLoading = () => ({
    type: types.uiFinishLoading
})

export const filterClosed = () => ({
    type: types.filtroClosed
})
export const filterOpened = () => ({
    type: types.filtroOpened
})

export const startSearch = () => ({
    type: types.searchOpened
})
export const finishSearch = () => ({
    type: types.searchClosed
})