import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import { startLogout } from '../../actions/auth';
import { useDispatch, ReactReduxContext } from 'react-redux';
import { makeDELETE, makeGET, makeGETCUSTOM, makePOST } from '../../helpers/makeRequest';
import { IoRestaurant, IoSchoolSharp } from "react-icons/io5"
import { MdCardTravel, MdHotel } from "react-icons/md";
import { FaBriefcaseMedical, FaCcMastercard, FaCcVisa, FaCheckCircle, FaComment, FaFacebook, FaHeart, FaInstagram, FaLocationArrow, FaMoneyBillAlt, FaPhone, FaPhoneAlt, FaPlus, FaRegClock, FaRegUserCircle, FaSearchLocation, FaStar, FaWhatsapp } from "react-icons/fa";
import { FaComputer } from "react-icons/fa6";
import useEmblaCarousel from 'embla-carousel-react'
import { FiFacebook, FiTwitter } from "react-icons/fi";
import { FaRegHeart } from "react-icons/fa";
import { TbShare3 } from "react-icons/tb";
import { TbWorld } from "react-icons/tb";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Carousel from 'react-multi-carousel';
import GoogleMapReact from 'google-map-react';
import { useWindowDimensions } from '../../helpers/hooks';
import portada from '../../imgs/portada-negocio.jpg'
import logoNegocio from '../../imgs/logo-negocio.svg'
import fondoblog from '../../imgs/fondo.avif'
import prueba1 from '../../imgs/prueba1.webp'
import prueba2 from '../../imgs/prueba2.webp'
import prueba3 from '../../imgs/prueba3.webp'
import prueba4 from '../../imgs/prueba4.avif'
import "../../styles/info-styles.css";
import Heart from '@react-sandbox/heart'
import { getCookie } from '../../helpers/cookies';
import { setOpenLoginForm } from '../../actions/ui';

export const InformacionScreen = (props) => {
    const params = useParams()
    const { actualPage } = params
    const dispatch = useDispatch();

    const [openedHours, setOpenedHours] = useState(false)
    var store = useContext(ReactReduxContext).store.getState();

    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 3
        },
        mobil: {
            breakpoint: { max: 768, min: 0 },
            items: 1
        }
    }

    const responsive2 = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    const slides = [
        { term: "Restaurantes", icon: <IoRestaurant /> },
        { term: "Hoteles", icon: <MdHotel /> },
        { term: "Escuelas", icon: <IoSchoolSharp /> },
        { term: "Hospitales", icon: <FaBriefcaseMedical /> },
        { term: "Computadoras", icon: <FaComputer /> },
        { term: "Agencias de viaje", icon: <MdCardTravel /> },
    ]
    const validCoords = (coords) => {
        return Array.isArray(coords) ? ((!isNaN(coords[0]) && coords[0] != null) && (!isNaN(coords[1]) && coords[1] != null)) : false
    }

    const { height, width } = useWindowDimensions()
    const defaultProps = {
        center: {
            lat: 19.42053,
            lng: -99.09412
        },
        zoom: 10
    };
    const [locations, setLocations] = useState([
        {
            ubicacion: "Islas Maldivas, Italia",
            img: prueba1,
            score: 3.5,
            favorito: true,
        },
        {
            ubicacion: "Langhorne Manor, Pensilvania, United States",
            img: prueba2,
            score: 5,
            favorito: false,
        },
        {
            ubicacion: "Hopkins, Minnesota, United States",
            img: prueba3,
            score: 4.2,
            favorito: false,
        },
        {
            ubicacion: "Gainesville, Florida, United States",
            img: prueba4,
            score: 2.3,
            favorito: true,
        },
        {
            ubicacion: "Islas Maldivas, Italia",
            img: prueba1,
            score: 3.5,
            favorito: false,
        },
        {
            ubicacion: "Langhorne Manor, Pensilvania, United States",
            img: prueba2,
            score: 5,
            favorito: true,
        },
        {
            ubicacion: "Hopkins, Minnesota, United States",
            img: prueba3,
            score: 4.2,
            favorito: false,
        },
        {
            ubicacion: "Gainesville, Florida, United States",
            img: prueba4,
            score: 2.3,
            favorito: true,
        },
    ])

    useEffect(async () => {
        const args = window.location.pathname.replace("/resultados/", "").split("/")
        const searchTerm = args[0], page = args[args.length - 1]

        if (!store?.ui?.currentBusiness?.listadoid) {
            navigator.geolocation.getCurrentPosition(async position => {
                const resp = await makeGET("business/getBusiness?listadoid=" + args[args.length - 1] +
                    "&lat=" + position.coords.latitude + "&lng=" + position.coords.longitude
                )
                setData(resp.data.length != 0 ? resp.data[0] : {})
            }, err => console.log(err))

        } else {
            setData(store?.ui?.currentBusiness)
        }
    }, [])

    const [data, setData] = useState({})
    const [horariosText, setHorariosText] = useState([])
    const [openStatus, setOpenStatus] = useState({})
    useEffect(async () => {
        const wrapHorario = (hora) => {
            hora = String(hora)
            let split = hora.split(":")
            split.pop()
            return split.join(":")
        }
        let horariosTmp = [], horariosRange = []
        let isOpened = false
        if (data?.horarios && !data?.horariosTmp) {
            if (data?.horarios?.semana) {
                horariosTmp = [...horariosTmp, "Lunes a Viernes: " + wrapHorario(data?.horarios?.semana?.open)
                    + " - " + wrapHorario(data?.horarios?.semana?.close)]
                horariosRange = [...horariosRange, {
                    days: [1, 2, 3, 4, 5],
                    open: parseInt((data?.horarios?.semana?.open)?.split(":")[0]),
                    close: parseInt((data?.horarios?.semana?.close)?.split(":")[0]),
                }]
            }
            if (data?.horarios?.sabado) {
                horariosTmp = [...horariosTmp, "Sabado: " + wrapHorario(data?.horarios?.sabado?.open)
                    + " - " + wrapHorario(data?.horarios?.sabado?.close)]
                horariosRange = [...horariosRange, {
                    days: [6],
                    open: parseInt((data?.horarios?.sabado?.open)?.split(":")[0]),
                    close: parseInt((data?.horarios?.sabado?.close)?.split(":")[0]),
                }]
            }
            if (data?.horarios?.domingo) {
                horariosTmp = [...horariosTmp, "Domingo: " + wrapHorario(data?.horarios?.domingo?.open)
                    + " - " + wrapHorario(data?.horarios?.domingo?.close)]
                horariosRange = [...horariosRange, {
                    days: [0],
                    open: parseInt((data?.horarios?.domingo?.open)?.split(":")[0]),
                    close: parseInt((data?.horarios?.domingo?.close)?.split(":")[0]),
                }]
            }
        }

        const currentDate = new Date()
        const finded = horariosRange.find(v => v.days.includes(currentDate.getDay()))
        if (finded?.open <= currentDate.getHours() && finded?.close >= currentDate.getHours()) {
            isOpened = true
        }

        setOpenStatus({
            isOpened, nextHour: ((isOpened ? finded?.close : finded?.open) + "hrs")
        })
        setHorariosText([...horariosTmp])
    }, [data])

    const renderMarkers = (map, maps) => {
        const pos = new maps.LatLng(data.coordenadas[1], data.coordenadas[0])

        let marker = new maps.Marker({
            position: pos,
            map,
            title: store?.ui?.currentBusiness?.nombre
        });
    }

    const changeValue = (key, val) => {
        let tmp = { ...data }
        tmp[key] = val
        setData({ ...tmp })
    }

    const share = async () => {
        const shareData = {
            title: data?.nombre,
            text: "¡Te comparto este negocio que encontré en Sección Amarilla!",
            url: window.location.href,
        }
        try {
            await navigator.share(shareData);
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='row m-0 p-0 br-10' style={{ maxWidth: width > 1400 ? "80%" : "" }}>
            <div className='col-12 col-md-9 mt-5 p-0'>
                <div className="card text-white ml-4 mr-3 mb-4 border-0">
                    <img className="card-img br-15" width={"100%"} height={"300px"}
                        src={portada}
                        alt="Card image" />
                    <div className="card-img-overlay d-flex align-items-start flex-column shadow-card-right">
                        <img className="mb-5" height={"30%"} src={logoNegocio} alt="Logo negocio" />
                        <h1 className="card-title m-0 card-font-h1">Nombre del negocio</h1>
                        
                    </div>
                </div>
                <div className='row m-3' style={{ borderRadius: "10px" }}>
                    {/* <Zoom duration={300} triggerOnce> */}
                    <div className='col-12 text-right text-muted fs-16'>
                        <a id='share' role='button' onClick={() => {
                            share()
                        }}
                            className='share-link'>
                            <TbShare3 /><span> Compartir</span>
                        </a>
                        <a id='save' role='button'
                            className='share-link' onClick={() => {
                                if (getCookie("token")) {
                                    if (data?.favorito) {
                                        makeDELETE("favourites/delete",
                                            {
                                                id_favourite: data?.favourite_info?.id_favourite
                                            })
                                    } else {
                                        makePOST("favourites/add",
                                            {
                                                business_id: data.listadoid,
                                                list_name: "general"
                                            })
                                    }
                                    changeValue("favorito", !data.favorito)
                                } else {
                                    dispatch(setOpenLoginForm(true))
                                }
                            }}>
                            <Heart className={'d-inline' + (!data.favorito ? " heart-home-not" : "")}
                                inactiveColor='lightgrey' width={16} active={data.favorito} /><u> Guardar</u>
                        </a>
                    </div>
                    {/* </Zoom> */}

                    <div className='col-12 col-md-7 mb-4 pt-3'>
                        {/* <Zoom duration={300} triggerOnce> */}
                        <div className='row'>
                            <div className='col-12'>
                                <h1 itemProp="name" className='h3'>
                                    {data?.nombre}
                                </h1>
                                {data?.distancia &&
                                    <p className='h6 text-muted'>
                                        A {data?.distancia}km de ti
                                    </p>
                                }
                            </div>
                            <div className='col-12' itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
                                <p className='p-0 m-0 text-muted' itemProp="ratingValue">
                                    {data?.score} <i data-star={data?.score}></i> <span itemProp="reviewCount">20 evaluaciones</span>
                                </p>
                                <div className='row fs-30'>
                                    <div className='col-7'>
                                        <FaInstagram
                                            id='facebook-info'
                                            className='mr-4'
                                            aria-label='Red social Instagram'
                                            rel='nofollow'
                                            role='button'
                                        />
                                        <FaWhatsapp
                                            id='whatsapp-info'
                                            className='mr-4'
                                            aria-label='Red social Whatsapp'
                                            rel='nofollow'
                                            role='button'
                                        />
                                        <FiFacebook
                                            id='facebook-info'
                                            className='mr-4'
                                            aria-label='Red social Facebook'
                                            rel='nofollow'
                                            role='button'
                                        />
                                        <FiTwitter
                                            id='twitter-info'
                                            className='mr-4'
                                            aria-label='Red social Twitter'
                                            rel='nofollow'
                                            role='button'
                                        />
                                    </div>
                                    <div className='col-5 text-right'>
                                        <a
                                            id='web'
                                            target='_blank'
                                            href="https://www.seccionamarilla.com.mx"
                                            className="btn btn-black rounded-pill"

                                            rel='nofollow'
                                        >
                                            <TbWorld className='fs-30' /> Ir al sitio
                                        </a>
                                    </div>
                                    <div className='col-12 mt-3'>
                                        <h2 className='h5 font-weight-bold'>
                                            Sobre nosotros
                                        </h2>
                                        <p itemProp="description" className='h5 text-muted'>
                                            Lorem ipsum dolor sit amet, consectetuer
                                            adipiscing elit, sed diam nonummy nibh
                                            euismod tincidunt ut laoreet...
                                        </p>
                                        <p className='h5 font-weight-bold'>
                                            <span>Ver más</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </Zoom> */}
                    </div>
                    <div className='col-12 col-md-5 mb-3'>
                        <div className='row mt-3'>
                            {JSON.stringify(data?.horarios) != "{}" &&
                                <div className='col-12 mb-3'>
                                    <p onClick={() => {
                                        setOpenedHours(!openedHours)
                                    }} role='button' className='h5'>
                                        <FaRegClock />&nbsp;
                                        <span className='text-success'>
                                            {openStatus.isOpened ? "Abierto" : "Cerrado"}
                                        </span>
                                        &#x2022; {openStatus.isOpened ? "Cierra" : "Abre"} a las {openStatus.nextHour} &nbsp;
                                        {openedHours ? <IoIosArrowUp /> : <IoIosArrowDown />}
                                    </p>
                                    {openedHours &&
                                        horariosText.map((v) => (
                                            <div className='' itemProp="openingHours">
                                                <p className=''>{v}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            <div className='col-6 mb-3'>
                                <h2 className='h5'>
                                    <span>Categorías</span>
                                </h2>
                            </div>
                            <div className='col-6 mb-3'>
                                <a href={"tel:" + data?.telefono} className="btn btn-yellow rounded-pill btn-block">
                                    <FaPhoneAlt /> LLAMAR
                                </a>
                            </div>
                            {slides.map((slide, idx) => (
                                <div key={idx} className='col-4 text-center category-slider-info'>
                                    {slide.icon}
                                    <p className='text-muted p-0 m-0 fs-11'>
                                        {slide.term}
                                    </p>
                                </div>
                            ))}
                        </div>
                        {data?.productos?.length > 0 &&
                            <>
                                <h2 className='h5 font-weight-bold mt-3'>
                                    Lo que ofrece este lugar
                                </h2>
                                <div className='row'>
                                    {data?.productos ?
                                        data?.productos?.map((slide, idx) => (
                                            <div key={idx} className='col-12 text-center category-slider-info'>
                                                <p className='text-muted p-0 m-0 fs-16'>
                                                    - {slide}
                                                </p>
                                            </div>
                                        )) :
                                        slides.map((slide, idx) => (
                                            <div key={idx} className='col-12 text-center category-slider-info'>
                                                {slide.icon}
                                                <p className='text-muted p-0 m-0 fs-16'>
                                                    - {slide.term}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                    {/* </Zoom> */}
                    <div className='col-12 mb-2 pt-3'>
                        {/* <Zoom duration={300} triggerOnce> */}
                        <Carousel arrows={true} responsive={responsive1} rewind={true}
                            rewindWithAnimation={true} style={{ zIndex: 200 }}>
                            {locations.map((v, i) => (
                                <div className='m-1' key={i}>
                                    <img key={i} className="w-100 image-aspect-info"
                                        itemProp='image'
                                        src={v.img}
                                        alt="Card image cap" />
                                </div>
                            ))}
                        </Carousel>
                        {/* </Zoom> */}
                    </div>

                </div>
                <div className='row mr-3 ml-3' style={{ borderRadius: "10px" }}>
                    <div className='col-12 col-md-6 mb-3'>
                        <h2 className='h5 font-weight-bold mt-3'>
                            <FaStar /> Evaluaciones
                        </h2>
                        {locations.slice(0, 3).map((v, i) => (
                            <div className='mb-3'>
                                <div className='d-flex'>
                                    <div className='w-50'>
                                        <p className='h5 font-weight-bold'><FaRegUserCircle /> Jose Carlos </p>
                                    </div>
                                    <div className='w-50'>
                                        <a href="#" className="btn rounded-pill verified-info">
                                            Verificado <FaCheckCircle />
                                        </a>
                                    </div>
                                </div>
                                <p className='h5 text-muted'>
                                    Buen hotel, con bastantes ameninades, limpio,
                                    personal atento, definitivamente me volvería
                                    a quedar con ellos cada que viaje fuera de la
                                    ciudad.
                                    <br />
                                    <span className='h6'>{locations[0].score} <i data-star={locations[0].score}></i></span>
                                </p>
                            </div>
                        ))}
                        <p className='h5 font-weight-bold'>
                            <span>Ver más</span>
                        </p>
                    </div>
                    {validCoords(data?.coordenadas) &&
                        <div className='col-12 col-md-6 mb-3'>
                            <h2 className='h5 font-weight-bold'>
                                Dónde nos encontramos
                            </h2>
                            <div className='aspect-map'>
                                {data?.listadoid &&
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                                        defaultCenter={data?.coordenadas
                                            ? {
                                                lat: data?.coordenadas[1],
                                                lng: data?.coordenadas[0]
                                            } : defaultProps.center}
                                        defaultZoom={defaultProps.zoom}
                                        yesIWantToUseGoogleMapApiInternals={true}
                                        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                                    >
                                    </GoogleMapReact>}
                            </div>
                        </div>}
                </div>
            </div>
            {
                width > 768 &&
                <div className='col-0 col-md-3 mt-5'>
                    <div className='p-2 text-center mb-3 bg-section'>
                        <h2 className='h5'>
                            ¿Crees que esta información está errónea?
                        </h2>
                        <a href="#" className="btn rounded-pill btn-yellow">
                            Sugerir una edición
                        </a>
                    </div>

                    <div className='p-2 text-center mb-3 bg-section'>
                        <h2 className='h5'>
                            ¿Es tu negocio?
                        </h2>
                        <a href="#" className="btn rounded-pill btn-yellow">
                            ¡Reclámalo ahora!
                        </a>
                    </div>

                    <p className='h5 text-center'>
                        Te puede interesar
                    </p>
                    <div className='container mt-50'>
                        <div className='position-relative'>
                            <FaComment className='section-blog-info' />
                            <h2 id='conoceNuestroBlog' className='title-blog-info'>
                                Conoce nuestro Blog
                            </h2>
                        </div>
                        <Carousel arrows={true} autoPlay={false} responsive={responsive2} rewind={true}
                            rewindWithAnimation={true} className='carousel'
                            afterChange={(previousSlide, { currentSlide, onMove }) => {
                            }}>
                            {locations.map((slide, idx) => (
                                <div className="card text-white border-0">
                                    <img
                                        className="card-img br-10"
                                        src={fondoblog}
                                        alt="Card image"
                                    />
                                    <div className="card-img-overlay text-right d-flex align-items-end flex-column blog-bg-info">
                                        <p className="card-title m-0 blog-font-info">Logo</p>
                                        <div className='mt-auto w-75 mb-3'>
                                            <p className='blog-font-info'>
                                                Actividades para los niños en Semana Santa
                                            </p>
                                            <a href="https://blogseccion.amarilio.net/" target='_blank' className="btn rounded-pill btn-yellow">
                                                Leer más
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            }
        </div>
    )
}
